import * as React from "react"
import { Heading, Text, Stack } from "@chakra-ui/react"

export const SnapshotField: React.FC<{
  title: string
  value: string | number | null
}> = (props) => {
  return (
    <>
      {props.value !== null && (
        <Stack spacing="12px" paddingBottom={8}>
          <Heading fontSize={18} margin={0} padding={0} color="gray.700">
            {props.title}
          </Heading>

          <Text
            fontSize={16}
            lineHeight="24px"
            color="gray.600"
            whiteSpace="pre-wrap">
            {props.children || props.value}
          </Text>
        </Stack>
      )}
    </>
  )
}
