import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { AuthenticationContext } from "../contexts/Authentication"

// Augment the Window type to include intercomSettings
declare global {
  interface Window {
    Intercom?: (
      command: "update" | "boot",
      args: IntercomUpdateParams | IntercomBootParams
    ) => any
  }
}

export interface IntercomUpdateParams {
  name?: string
  email?: string
  created_at?: string
  last_update_at?: number
}

export interface IntercomBootParams {
  app_id: string
}

export interface Props extends RouteComponentProps {
  appId: string
}

export const IntercomProvider: React.FC<Props> = (props) => {
  // Bring in the current user so we know which mode to render intercom in
  const { authState } = React.useContext(AuthenticationContext)

  // Destructure the props we will use directly
  const { appId, children, location } = props

  // When the component mounts, create and attach the Intercom JS widget
  React.useEffect(() => {
    const scriptNode = document.createElement("script")

    // Build and attach the node
    scriptNode.innerText =
      "(function(){var w=window;var ic=w.Intercom;if(typeof ic==='function'){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/iboqn479';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();"
    document.body.appendChild(scriptNode)

    // Clean up the node when the component unmounts
    return () => {
      document.body.removeChild(scriptNode)
    }
  }, [])

  // Whenever the router transitions, tell Intercom to do its update stuff
  React.useEffect(() => {
    if (location && location.pathname) {
      if (window.Intercom) {
        const lastUpdateAt = Math.round(new Date().getTime() / 1000)
        window.Intercom("update", { last_update_at: lastUpdateAt })
      }
    }
  }, [location])

  // Whenever authState changes we need to update intercom settings
  React.useEffect(() => {
    // Check if there is a current user and set up Intercom accordingly
    if (authState.name === "AUTHENTICATED") {
      // Pick off the user for brevity
      const { user } = authState.authedUser

      // Calculate unix timestamp representing the user's age
      const timestamp = new Date(user.insertedAt).getTime() / 1000

      // Set "logged in" intercom settings
      window.Intercom &&
        window.Intercom("boot", {
          app_id: appId,
          name: user.givenName + " " + user.familyName,
          email: user.email,
          created_at: timestamp.toString(),
        })
    } else {
      // Set "public/logged out" intercom settings
      window.Intercom && window.Intercom("boot", { app_id: appId })
    }
  }, [appId, authState])

  // Render the remainder of the tree
  return <>{children}</>
}
