// A utility which will translate Absinthe errors to Formik errors
// NOTE: Supports only a single operation for document
// TODO:
// 1. Add support for multiple operations per document
// 2. Strongly type
export const applyAbsintheErrors = (errorResponse: any, form: any) => {
  // The GraphQL document only has one root field, so we can assume the
  // error we care about is the first element and discard the rest
  const validationError = errorResponse["graphQLErrors"][0]

  // Grab each error from the `details` key which should already
  // match up to a form field and apply them
  Object.keys(validationError.details).forEach((fieldName) => {
    // If the field error is an array of strings, we can assume the
    // respective formik field is not a nested/array field and set the
    // error message directly
    if (typeof validationError.details[fieldName][0] === "string") {
      return form.setFieldError(
        fieldName,
        validationError.details[fieldName].join("\n")
      )
    }

    // If the error is an array of objects, we can assume the respective
    // formik field is a nested/array field so we need to set the error
    // messages on the correct index and field name
    if (typeof validationError.details[fieldName][0] === "object") {
      validationError.details[fieldName].forEach(
        (fieldError: any, index: number) => {
          // If the field object has more than one key, then it has errors
          if (Object.keys(fieldError).length >= 1) {
            Object.keys(fieldError).forEach((nestedFieldName) => {
              form.setFieldError(
                `${fieldName}.${index}.${nestedFieldName}`,
                fieldError[nestedFieldName].join("\n")
              )
            })
          }
        }
      )
    }
  })
}

export const nullifyBlankStringFields = (obj: any) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      value === "" ? null : value,
    ])
  )
}
