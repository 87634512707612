import * as React from "react"
import { useApolloClient, useMutation } from "@apollo/react-hooks"
import ReactGA from "react-ga"

import {
  InvestorUser,
  StartupUser,
  AuthenticatedStartupUser,
  AuthenticatedInvestorUser,
} from "../graphql/types"

import {
  Data as CreateStartupSessionData,
  Variables as CreateStartupSessionVariables,
  CREATE_STARTUP_SESSION,
} from "../graphql/mutations/create-startup-session"

import {
  Data as AcceptInvitationData,
  Variables as AcceptInvitationVariables,
  ACCEPT_INVITATION,
} from "../graphql/mutations/accept-invitation"
import { ExecutionResult } from "graphql"

export type User = StartupUser | InvestorUser

export type UserKind = "startup_user" | "investor_user"

export type AuthenticatedUser =
  | AuthenticatedStartupUser
  | AuthenticatedInvestorUser

export type AuthenticationAction =
  | { kind: "BEGIN_AUTHENTICATION" }
  | {
      kind: "AUTHENTICATION_COMPLETE"
      authedUser: AuthenticatedUser
      userKind: UserKind
    }
  | { kind: "AUTHENTICATION_FAILED"; error: string }
  | { kind: "SIGN_OUT" }

export type AuthenticationState =
  | { name: "UNAUTHENTICATED"; error?: string }
  | { name: "AUTHENTICATION_IN_PROGRESS" }
  | {
      name: "AUTHENTICATED"
      authedUser: AuthenticatedUser
      userKind: UserKind
    }

export type AcceptInvitationHandler = (
  invitationCode: string,
  password: string
) => Promise<ExecutionResult<AcceptInvitationData>>

export type SignInHandler = (
  email: string,
  password: string
) => Promise<ExecutionResult<CreateStartupSessionData>>

export type SignOutHandler = () => Promise<void>

export type AuthenticationContextType = {
  authState: AuthenticationState
  acceptInvitation: AcceptInvitationHandler
  signIn: SignInHandler
  signOut: SignOutHandler
}

function initialState(
  authedUser: AuthenticatedUser | undefined
): AuthenticationState {
  if (authedUser) {
    return { name: "AUTHENTICATED", authedUser, userKind: "startup_user" }
  } else {
    return { name: "UNAUTHENTICATED" }
  }
}

export const AuthenticationContext = React.createContext<AuthenticationContextType>(
  {
    authState: initialState(undefined),
    acceptInvitation: (_invitationCode: string, _password: string) =>
      Promise.reject(),
    signIn: (_email: string, _password: string) => Promise.reject(),
    signOut: () => Promise.reject(),
  }
)

function reducer(
  _state: AuthenticationState,
  action: AuthenticationAction
): AuthenticationState {
  switch (action.kind) {
    case "BEGIN_AUTHENTICATION":
      return { name: "AUTHENTICATION_IN_PROGRESS" }
    case "AUTHENTICATION_COMPLETE": {
      ReactGA.set({user_id: action.authedUser.user.id})

      return {
        name: "AUTHENTICATED",
        authedUser: action.authedUser,
        userKind: "startup_user",
      }
    }
    case "AUTHENTICATION_FAILED":
      return { name: "UNAUTHENTICATED", error: action.error }
    case "SIGN_OUT":
      return { name: "UNAUTHENTICATED" }
  }
}

export const Authentication: React.FC = (props) => {
  const apolloClient = useApolloClient()

  const existingUser = localStorage.getItem("currentUser")
  const authenticatedUser = existingUser ? JSON.parse(existingUser) : undefined

  const [authState, dispatch] = React.useReducer(
    reducer,
    initialState(authenticatedUser)
  )

  const [createStartupSessionMutation] = useMutation<
    CreateStartupSessionData,
    CreateStartupSessionVariables
  >(CREATE_STARTUP_SESSION)

  const [acceptInvitationMutation] = useMutation<
    AcceptInvitationData,
    AcceptInvitationVariables
  >(ACCEPT_INVITATION)

  const acceptInvitation = React.useCallback(
    async (invitationCode: string, password: string) => {
      dispatch({ kind: "BEGIN_AUTHENTICATION" })
      try {
        const result = await acceptInvitationMutation({
          variables: {
            data: {
              invitationCode,
              password,
            },
          },
        })
        if (result.data?.acceptInvitation) {
          const authedUser = result.data.acceptInvitation
          localStorage.setItem("currentUser", JSON.stringify(authedUser))
          localStorage.setItem("accessToken", authedUser.session.token)
          dispatch({
            kind: "AUTHENTICATION_COMPLETE",
            authedUser,
            userKind: "startup_user",
          })
          return Promise.resolve(result)
        } else {
          // This is *probably* unreachable but the type system can't prove it.
          // So be defensive.
          dispatch({
            kind: "AUTHENTICATION_FAILED",
            error: "Sign-in failed!",
          })
          return Promise.reject("Sign-in failed!")
        }
      } catch (errors) {
        dispatch({ kind: "AUTHENTICATION_FAILED", error: "Sign-in failed!" })
        return await Promise.reject(errors)
      }
    },
    [acceptInvitationMutation, dispatch]
  )

  const signIn = React.useCallback(
    async (email: string, password: string) => {
      dispatch({ kind: "BEGIN_AUTHENTICATION" })
      const result = await createStartupSessionMutation({
        variables: {
          data: {
            password,
            email,
          },
        },
      })
      if (result.data?.createSession) {
        const authedUser = result.data.createSession
        localStorage.setItem("currentUser", JSON.stringify(authedUser))
        localStorage.setItem("accessToken", authedUser.session.token)
        dispatch({
          kind: "AUTHENTICATION_COMPLETE",
          authedUser,
          userKind: "startup_user",
        })
        return Promise.resolve(result)
      } else {
        dispatch({ kind: "AUTHENTICATION_FAILED", error: "Sign-in failed!" })
        return Promise.reject("Sign-in failed!")
      }
    },
    [createStartupSessionMutation, dispatch]
  )

  const signOut = React.useCallback(() => {
    apolloClient.clearStore()
    localStorage.removeItem("currentUser")
    localStorage.removeItem("accessToken")
    dispatch({ kind: "SIGN_OUT" })
    return Promise.resolve()
  }, [apolloClient])

  return (
    <AuthenticationContext.Provider
      value={{
        authState,
        acceptInvitation,
        signIn,
        signOut,
      }}>
      {props.children}
    </AuthenticationContext.Provider>
  )
}
