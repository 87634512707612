import * as React from "react"
import { Formik, Form, Field, FormikProps, FormikHelpers } from "formik"

import { Divider, Button, ButtonGroup, Text } from "@chakra-ui/react"

import { TextInput } from "../inputs/TextInput"
import { TextareaInput } from "../inputs/TextareaInput"
import { SelectInput } from "../inputs/SelectInput"

import { useUpdateStartup } from "../../../hooks/useUpdateStartup"
import {
  applyAbsintheErrors,
  nullifyBlankStringFields,
} from "../../../utilities/forms"

export interface FormValues {
  previousFundingRounds: string | null
  currentFundingRound: string | null
  fundingUsesAndRunway: string | null
  investmentAsk: number | null
  valuation: number | null
  dealType: string | null
  investorPreferences: string[]
}

export interface Props {
  startup: FormValues
  onCommit: () => void
  onError: () => void
}

export type FormUtils = FormikHelpers<FormValues>

export const Funding: React.FC<Props> = (props) => {
  // Prepare mutation for updating over the API
  const [updateStartup] = useUpdateStartup()

  // Set up the initial values for the form
  const initialValues: FormValues = React.useMemo(() => {
    return {
      previousFundingRounds: props.startup.previousFundingRounds,
      currentFundingRound: props.startup.currentFundingRound,
      fundingUsesAndRunway: props.startup.fundingUsesAndRunway,
      investmentAsk: props.startup.investmentAsk,
      valuation: props.startup.valuation,
      dealType: props.startup.dealType,
      investorPreferences: props.startup.investorPreferences,
    }
  }, [props.startup])

  // Set up the submission handler
  const onSubmit = async (values: FormValues, form: FormUtils) => {
    // Build the mutation's parameteres
    const params = {
      variables: {
        data: nullifyBlankStringFields(values),
      },
    }

    // Fire off the mutation and take action accordingly based on the result
    return updateStartup(params)
      .then((result) => {
        if (result.data?.updateStartup) {
          props.onCommit()
        }
      })
      .catch((errorResponse) => {
        props.onError()
        applyAbsintheErrors(errorResponse, form)
      })
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(form: FormikProps<FormValues>) => {
        return (
          <Form>
            <Text pb={4}>
              Tell us about your previous funding history and what you’re
              looking for now.
            </Text>

            <Field
              name="previousFundingRounds"
              label="Previous funding rounds"
              description="List rounds below, including: amount, date and investor types"
              component={TextareaInput}
            />

            <Field
              name="currentFundingRound"
              label="Current funding round stage"
              component={SelectInput}
              options={[
                "Pre-Seed",
                "Seed",
                "Pre-Series A",
                "Series A",
                "Series B",
                "Other",
              ]}
            />

            <Field
              name="fundingUsesAndRunway"
              label="Uses & runway if raising (description)"
              component={TextareaInput}
            />

            <Field
              name="investmentAsk"
              label="Investment Ask (dollar amount)"
              component={TextInput}
              isNumber
            />

            <Field
              name="valuation"
              label="Valuation (dollar amount)"
              component={TextInput}
              isNumber
            />

            <Field
              name="dealType"
              label="Security"
              component={SelectInput}
              options={[
                "Equity",
                "Convertible Note",
                "SAFE Note",
                "Bridging Round",
              ]}
            />

            <Field
              name="investorPreferences"
              label="Investor type preferences"
              component={SelectInput}
              options={[
                "Venture Capital",
                "Family Office",
                "Angel",
                "Corporate Venture Capital",
              ]}
              isMulti
            />

            <Divider my={8} borderColor="gray.300" />

            <ButtonGroup spacing={4}>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={form.isSubmitting}>
                Update Snapshot
              </Button>

              <Button
                type="reset"
                variant="ghost"
                colorScheme="red"
                isDisabled={form.isSubmitting}>
                Reset
              </Button>
            </ButtonGroup>
          </Form>
        )
      }}
    </Formik>
  )
}
