import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { Text } from "@chakra-ui/react"

import { PageHeader } from "../PageHeader"

export const DataRoom: React.FC<RouteComponentProps> = props => {
  return (
    <>
      <PageHeader headingText="Data Room (Coming Soon!)" />
      <Text>
        Soon, you’ll be able to organise and upload your due diligence documents
        so you can control your entire capital raising process in one place.
      </Text>
    </>
  )
}
