import * as React from "react"
import { RouteComponentProps, navigate } from "@reach/router"
import { Box, Flex, Heading, Text, useToast } from "@chakra-ui/react"

import { AuthenticatedStartupUser } from "../../graphql/types"
import { StartupLoginForm } from "../forms/StartupLoginForm"

import Logo from "../../assets/logo.svg"

export const LoginStartup: React.FC<RouteComponentProps> = (props) => {
  // A utility for displaying toasts
  const toast = useToast()

  // Keep a piece of state which indicates if we should force the login
  // form to be disabled
  const [disableForm, setDisableForm] = React.useState<boolean>(false)

  // Handle when a new Investor is successfully registered
  const handleLoginSuccess = async (startup: AuthenticatedStartupUser) => {
    // TODO: Remove this hack
    setDisableForm(true)
    await navigate("/")

    toast({
      title: `Welcome back, ${startup.user.givenName}!`,
      description: "Our investors are looking forward to chatting",
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  }

  // Handle when an error occured during Investor creation
  const handleSessionError = () => {
    toast({
      title: "There was a problem signing you in",
      description: "Please try again",
      status: "warning",
      duration: 3000,
      isClosable: true,
    })
  }

  return (
    <Box maxWidth="500px" margin="auto" px={6} padding={10}>
      <Flex align="center" justify="center" mb={2}>
        <img src={Logo} alt="Kaspa" width="120px" height="40px" />
      </Flex>

      <Box mb={12}>
        <Heading fontFamily="Roboto" textAlign="center" py={3}>
          Login as a Startup
        </Heading>

        <Text fontSize={18} textAlign="center" color="gray.500">
          If your startup is a prime investment opportunity, then you've come to
          the right place
        </Text>
      </Box>

      <Box rounded={4} boxShadow="0 5px 12px rgba(194,204,212,0.5)">
        <Box paddingX={6} paddingY={6}>
          <StartupLoginForm
            isDisabled={disableForm}
            onLogin={handleLoginSuccess}
            onError={handleSessionError}
          />
        </Box>
      </Box>
    </Box>
  )
}
