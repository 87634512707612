import * as React from "react"
import { Divider, Button, ButtonGroup, Text } from "@chakra-ui/react"
import { Form, Formik, FormikHelpers, FormikProps, Field } from "formik"

import { TextInput } from "../inputs/TextInput"
import { SelectInput } from "../inputs/SelectInput"
import { BooleanInput } from "../inputs/BooleanInput"
import { CountryInput } from "../inputs/CountryInput"
import { FileUploadInput } from "../inputs/FileUploadInput"

import { useUpdateStartup } from "../../../hooks/useUpdateStartup"
import {
  applyAbsintheErrors,
  nullifyBlankStringFields,
} from "../../../utilities/forms"

export interface FormValues {
  name: string
  slug: string
  logoImageId: string | null
  slogan: string | null
  websiteUrl: string | null
  pitchDeckUrl: string | null
  locationCountry: string | null
  countryOfIncorporation: string | null
  yearFounded: number | null
  businessModelTypes: string[] | null
  industry: string | null
  verticals: string[] | null
  customerFocus: string | null
  stage: string | null
  seekingFunding: boolean | null
}

export interface Props {
  startup: FormValues
  onCommit: () => void
  onError: () => void
}

export type FormUtils = FormikHelpers<FormValues>

export const Basics: React.FC<Props> = (props) => {
  // Prepare mutation for updating over the API
  const [updateStartup] = useUpdateStartup()

  // Set up the initial values for the form
  const initialValues: FormValues = React.useMemo(() => {
    return {
      name: props.startup.name,
      slug: props.startup.slug,
      logoImageId: props.startup.logoImageId,
      slogan: props.startup.slogan,
      websiteUrl: props.startup.websiteUrl,
      pitchDeckUrl: props.startup.pitchDeckUrl,
      locationCountry: props.startup.locationCountry,
      countryOfIncorporation: props.startup.countryOfIncorporation,
      yearFounded: props.startup.yearFounded,
      businessModelTypes: props.startup.businessModelTypes,
      industry: props.startup.industry,
      verticals: props.startup.verticals,
      customerFocus: props.startup.customerFocus,
      stage: props.startup.stage,
      seekingFunding: props.startup.seekingFunding,
    }
  }, [props.startup])

  // Set up the submission handler
  const onSubmit = async (values: FormValues, form: FormUtils) => {
    // Build the mutation's parameteres
    const params = {
      variables: {
        data: nullifyBlankStringFields(values),
      },
    }

    // Fire off the mutation and take action accordingly based on the result
    return updateStartup(params)
      .then((result) => {
        if (result.data?.updateStartup) {
          props.onCommit()
        }
      })
      .catch((errorResponse) => {
        props.onError()
        applyAbsintheErrors(errorResponse, form)
      })
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(form: FormikProps<FormValues>) => {
        return (
          <Form>
            <Text paddingBottom={2}>
              Let’s start with the basics. Tell us a bit about your company.
            </Text>

            <Field name="name" label="Company Name" component={TextInput} />
            <Field name="slug" label="Slug" component={TextInput} />
            <Field
              name="logoImageId"
              label="Company Logo"
              component={FileUploadInput}
              previewWidth="150px"
            />

            <Field
              name="slogan"
              label="Your one line pitch"
              component={TextInput}
            />

            <Field
              name="websiteUrl"
              label="Website URL"
              component={TextInput}
            />

            <Field
              name="pitchDeckUrl"
              label="A link to your pitch deck"
              component={TextInput}
            />

            <Field
              name="locationCountry"
              label="Primary country of operations"
              component={CountryInput}
            />

            <Field
              name="countryOfIncorporation"
              label="Country in which the company is incorporated"
              component={CountryInput}
            />

            <Field
              name="yearFounded"
              label="Year founded"
              component={TextInput}
              isNumber
            />

            <Field
              name="businessModelTypes"
              label="Business model"
              component={SelectInput}
              options={[
                "SaaS",
                "PaaS",
                "Services",
                "Transaction Fees",
                "License",
                "Freemium",
                "Ecommerce",
                "Data-play",
                "Network-effect",
                "Consumer subscription",
                "Marketplace",
              ]}
              isMulti
            />

            <Field
              name="industry"
              label="Industry"
              component={SelectInput}
              options={[
                "Mobile",
                "Hardware",
                "Goods & Products",
                "Consumer Goods & Products",
                "Software & Internet",
                "Services",
                "Other",
              ]}
            />

            <Field
              name="verticals"
              label="Verticals"
              component={SelectInput}
              options={[
                "Any",
                "AI/ML",
                "AR/VR",
                "AgTech",
                "Autonomous Vehicles",
                "Biotech",
                "Blockchain",
                "Cannabis",
                "Cleantech",
                "Cloud Infrastructure",
                "Consumer",
                "Crypto",
                "Cybersecurity",
                "Data Analytics",
                "DeepTech",
                "Devtech",
                "Ecommerce",
                "EdTech",
                "Enterprise Solutions",
                "Entertainment",
                "Esports",
                "FinTech",
                "Foodtech",
                "Future of Work",
                "Gaming",
                "HRtech",
                "HealthTech",
                "Industry 4.0",
                "Legaltech",
                "MarketingTech",
                "MedTech",
                "Productivity & Automation",
                "Proptech",
                "Regtech",
                "Robotics",
                "SaaS",
                "Social Impact",
                "Traveltech",
              ]}
              isMulti
            />

            <Field
              name="customerFocus"
              label="Customer Focus"
              component={SelectInput}
              options={["B2B", "B2C", "B2B2C"]}
            />

            <Field
              name="stage"
              label="Stage"
              component={SelectInput}
              options={["Pre-product", "Pre-revenue", "Post-revenue"]}
            />

            <Field
              name="seekingFunding"
              label="Is your Startup currently seeking funding?"
              component={BooleanInput}
            />

            <Divider my={8} borderColor="gray.300" />

            <ButtonGroup spacing={4}>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={form.isSubmitting}>
                Update Snapshot
              </Button>

              <Button
                type="reset"
                variant="ghost"
                colorScheme="red"
                isDisabled={form.isSubmitting}>
                Reset
              </Button>
            </ButtonGroup>
          </Form>
        )
      }}
    </Formik>
  )
}
