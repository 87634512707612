// @ts-nocheck
import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { HttpLink } from "apollo-link-http"
import { setContext } from "apollo-link-context"
import { onError } from "apollo-link-error"
import { split } from "apollo-link"

// Routing
import { navigate } from "@reach/router"

// The below imports are specific to creating a socket link
// over the top of Phoenix channels and Absinthe
import * as AbsintheSocket from "@absinthe/socket"
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link"
import { Socket as PhoenixSocket } from "phoenix"
import { hasSubscription } from "@jumpn/utils-graphql"

// Early detect whether we're in development mode or not
export const isDevMode: boolean =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_ENV === undefined

// HTTP URI, set depending on whether we're in dev or prod
export const httpURI = isDevMode
  ? "http://api.localhost:4000/graphql"
  : process.env.REACT_APP_ENV === "staging"
  ? "https://api.staging.diligr.io/graphql"
  : "https://api.diligr.io/graphql"

// WebSocket URI, set depending on whether we're in dev or prod
export const wsURI = isDevMode
  ? "ws://api.localhost:4000/socket"
  : process.env.REACT_APP_ENV === "staging"
  ? "wss://api.staging.diligr.io/socket"
  : "wss://api.diligr.io/socket"

// Helper for determine the base path of the frontend
export const snapshotBaseURI = isDevMode
  ? "http://localhost:3000"
  : process.env.REACT_APP_ENV === "staging"
  ? "https://app.staging.getkaspa.com"
  : "https://kaspasnapshot.com"

// Create our basic HTTP link
const httpLink = new HttpLink({ uri: httpURI })

// Create an Absinthe socket over the top of a Phoenix channel, which
// will send the user's access token if it exists along with each transmission
const absintheSocket = AbsintheSocket.create(
  new PhoenixSocket(wsURI, {
    params: () => {
      if (localStorage.getItem("accessToken")) {
        return { token: localStorage.getItem("accessToken") }
      } else {
        return {}
      }
    },
  })
)

// Use the Absinthe helper to create a websocket link that Apollo can understand
// around the previously created absintheSocket
const socketLink = createAbsintheSocketLink(absintheSocket)

// Create a "split" link, which allows us to direct traffic over HTTP or WS
// depending on what sort of operation is underway. EG: If we are sending a
// query, using HTTP is fine. If we're sending a subscription, we want a
// websocket
const splitLink = split(
  (operation) => hasSubscription(operation.query),
  socketLink,
  httpLink
)

// Create an "authed" link which will attach the user's access token as an HTTP
// Authorization header if we are sending an operation over HTTP
const withTokenLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken")
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

// Create a link which will redirect the user to the login screen when Apollo
// experiences an error which indicates the user's access token is not valid
const redirectOnAuthErrorLink = onError(({ response, networkError }) => {
  // Was the response code a 401?
  const is401 = networkError && networkError.statusCode === 401

  // Does the response indicate an unauthenticated graphql error?
  const isUnauthentcated =
    response &&
    response.errors.length >= 1 &&
    response.errors.some(
      (error) =>
        error.message === "You must be logged in to perform this action"
    )

  // Perform "logout" logic if either of the session expired conditions
  // are met
  if (is401 === true || isUnauthentcated === true) {
    // Remove access token and current user cache
    localStorage.removeItem("accessToken")
    localStorage.removeItem("currentUser")

    // Redirect to the login screen
    navigate("/login")
  }
})

// Bring together the two links which handle authentication
const authLink = redirectOnAuthErrorLink.concat(withTokenLink)

// Now that we've setup different behaviours, let's bring them all together in
// to the one behaviour.
//
// It will instruct Apollo to send subscription documents over a websocket and
// queries and mutations over HTTP. It will also send an access token for either
// transport, in the format that is required for that transport
const finalLink = authLink.concat(splitLink)

// Create the client and export it
export const client = new ApolloClient({
  link: finalLink,
  cache: new InMemoryCache(),
})
