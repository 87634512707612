import * as React from "react"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/base"
import { CloudinaryContext } from "../../../contexts/Cloudinary"

export interface Props {
  id: string
  alt: string
}

export const UploadedImage: React.FC<Props> = ({ id, alt, ...props }) => {
  const { cloudName } = React.useContext(CloudinaryContext)

  const cld = React.useMemo(() => {
    return new Cloudinary({
      cloud: { cloudName: cloudName },
    })
  }, [cloudName])

  return (
    <AdvancedImage
      width="100%"
      height="100%"
      cldImg={cld.image(id)}
      alt={alt}
      {...props}
    />
  )
}
