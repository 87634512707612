import * as React from "react"
import { FieldProps, ErrorMessage } from "formik"

import {
  Flex,
  Input,
  Icon,
  FormControl,
  FormLabel,
  Text,
} from "@chakra-ui/react"

export interface Props {
  label?: string | boolean
  secret?: boolean
  placeholder?: string
  isNumber?: boolean
}

export const TextInput: React.FC<Props & FieldProps> = (props) => {
  // Cache the field name
  const fieldName: string = React.useMemo(() => props.field.name, [props.field])

  // Compute if the FormControl should be displayed as invalid
  const isInvalid: boolean = React.useMemo(() => {
    return (
      props.form.errors[fieldName] !== undefined &&
      props.form.touched[fieldName] === true
    )
  }, [props.form.errors, props.form.touched, fieldName])

  // Determine the type of input we want to use, derived from props.secret
  // and props.isNubmer
  const inputType = React.useMemo(() => {
    if (props.isNumber) {
      return "number"
    } else {
      return props.secret ? "password" : "text"
    }
  }, [props.secret, props.isNumber])

  // We've computed all the things we need - render the TextInput field
  return (
    <FormControl pb={4} width="100%" isInvalid={isInvalid}>
      {props.label !== false && (
        <FormLabel htmlFor={props.field.name}>{props.label}</FormLabel>
      )}

      <Input
        {...props.field}
        size="lg"
        backgroundColor="white"
        type={inputType}
        isDisabled={props.form.isSubmitting}
        id={props.field.name}
        placeholder={props.placeholder}
        value={props.field.value || ""}
      />

      <ErrorMessage name={props.field.name}>
        {(error) => (
          <Flex color="red.500" pl={1.5} mt={2} fontSize="sm" align="center">
            <Icon name="warning" mr="0.5em" aria-hidden />
            <Text lineHeight="normal">{error}</Text>
          </Flex>
        )}
      </ErrorMessage>
    </FormControl>
  )
}
