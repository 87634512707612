import * as React from "react"
import { Box, Heading, Stack, Text } from "@chakra-ui/react"

import { LargeSelectionTile } from "@app/components/ui/LargeSelectionTile"

import Logo from "@assets/logo.svg"
import Coins from "@assets/coins.svg"
import Unicorn from "@assets/unicorn.svg"

export type Props = {
  activeTile: "investor" | "startup" | "none"
  handleInvestorClick: () => void
  handleStartupClick: () => void
  handleInvestorContinue: () => void
  handleStartupContinue: () => void
}

export const LoginBasic: React.FC<Props> = ({
  activeTile,
  handleInvestorClick,
  handleStartupClick,
  handleInvestorContinue,
  handleStartupContinue,
}) => {
  return (
    <Box maxWidth="640px" margin="auto" padding={20}>
      <Box pb={6}>
        <img src={Logo} alt="Kaspa" width={120} height={40} />
      </Box>

      <Box pb={16}>
        <Heading fontFamily="Roboto" fontSize="2rem" pb={4} color="gray.800">
          Welcome Back.
          <br />
          Are you an Investor or a Startup?
        </Heading>

        <Text fontSize={18} maxWidth="480px" color="gray.400">
          You’re just two steps away from finding your dream investor or your
          perfectly matched startup founder
        </Text>
      </Box>

      <Stack maxWidth="530px" spacing={8}>
        <LargeSelectionTile
          isActive={activeTile === "investor"}
          icon={Coins}
          onClick={handleInvestorClick}
          onContinueClick={handleInvestorContinue}
          primaryText="Investor"
          subText="I'm looking for startups"
        />
        <LargeSelectionTile
          isActive={activeTile === "startup"}
          icon={Unicorn}
          onClick={handleStartupClick}
          onContinueClick={handleStartupContinue}
          primaryText="Startup"
          subText="I'm looking for investors"
        />
      </Stack>
    </Box>
  )
}
