import { useQuery } from "@apollo/react-hooks"
import { QueryResult } from "react-apollo"
import { WhereUniqueInput } from "../graphql/types"

import gql from "graphql-tag"

export type QueriedInvestor = {
  id: string
  name: string
  description: string | null
  logoUrl: string | null
  locationCity: string | null
  locationCountry: string | null
  categories: string[]
  websiteUrl?: string | null
  customerFocusPreferences: string[]
  industryPreferences: string[]
  verticalPreferences: string[]
  fundSize: string | null
  investmentRange: string | null
  portfolio: string | null
  contacts: Array<{
    name: string
    email: string
    title: string | null
    linkedinUrl: string | null
  }>
  geographicPreferences: Array<{
    continent: string | null
    country: string | null
    region: string | null
  }>
}

export const INVESTOR = gql`
  query investor($where: InvestorWhereUniqueInput!) {
    investor(where: $where) {
      id
      name
      description
      logoUrl
      locationCity
      locationCountry
      categories
      websiteUrl
      introUrl
      customerFocusPreferences
      industryPreferences
      verticalPreferences
      fundSize
      investmentRange
      portfolio
      contacts {
        name
        email
        title
        linkedinUrl
      }
      geographicPreferences {
        continent
        country
        region
      }
    }
  }
`

export interface Data {
  investor: QueriedInvestor
}

export interface Variables {
  where: WhereUniqueInput
}

export type UseInvestorHook = (
  investorId: string
) => [Data["investor"] | undefined | null, QueryResult<Data, Variables>]

export const useInvestor: UseInvestorHook = investorId => {
  // Run the query
  const query = useQuery<Data, Variables>(INVESTOR, {
    variables: { where: { id: investorId } },
  })

  // Return the result as an unwrapped tuple
  return [query.data?.investor, query]
}
