import * as React from "react"
import Select, { OptionsType } from "react-select"

import type { DropdownOption } from '../option'

export interface Props {
  options: ReadonlyArray<DropdownOption>
  isDisabled?: boolean
  onChange: (values: ReadonlyArray<DropdownOption>) => void
  defaultValue?: DropdownOption | null
  placeholder: string
}

export const MultiSelectionDropdown: React.FC<Props> = (props) => {
  const { onChange, defaultValue, options } = props

  const handleChange = React.useCallback<
    (selected: OptionsType<DropdownOption>, _actionMeta: any) => void
  >(
    (selected) => {
      onChange(selected)
    },
    [onChange]
  )

  return (
    <Select<DropdownOption, true>
      width="100%"
      onChange={handleChange}
      isClearable={true}
      closeMenuOnSelect={true}
      placeholder={props.placeholder}
      defaultValue={defaultValue}
      options={options}
      isMulti={true}
      isDisabled={props.isDisabled}
    />
  )
}
