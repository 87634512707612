import * as React from "react"
import { RouteComponentProps, navigate, Link as ReachLink } from "@reach/router"
import { InfoIcon } from "@chakra-ui/icons"

import { PageHeader } from "../PageHeader"
import { StartupSnapshotForm } from "../forms/StartupSnapshotForm"
import { useStartupSnapshot } from "../../hooks/useStartupSnapshot"
import { snapshotBaseURI } from "../../graphql"

import {
  Box,
  Code,
  Flex,
  Link,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Spinner,
} from "@chakra-ui/react"

export interface Props extends RouteComponentProps {
  id: string
  section?: string
}

export const StartupSnapshot: React.FC<Props> = (props) => {
  // Fetch the current user's startup's snapshot
  const [startupData, queryStatus] = useStartupSnapshot("id", props.id)

  // Map tab indexes to their labels so we can accurately change route
  const tabIndexLabelMap: { [index: string]: string } = React.useMemo(() => {
    return {
      "0": "basics",
      "1": "overview",
      "2": "team",
      "3": "metrics",
      "4": "funding",
      "5": "media",
    }
  }, [])

  // Keep a piece of state to control which tab is displayed
  const selectedTabIndex = React.useMemo(() => {
    const foundIndex = Object.keys(tabIndexLabelMap).find(
      (index) => tabIndexLabelMap[index] === props.section
    )

    if (foundIndex) {
      return parseInt(foundIndex, 10)
    } else {
      return 0
    }
  }, [props.section, tabIndexLabelMap])

  // Memoize the startup's public permalink
  const publicSnapshotHref = React.useMemo(() => {
    return startupData ? `${snapshotBaseURI}/startup/${startupData.slug}` : ""
  }, [startupData])

  // Handle when the user selects one of the snapshot tabs
  const handleTabChange = (index: number) => {
    const section = tabIndexLabelMap[index]
    navigate(`/snapshot/${section}`)
  }

  return (
    <>
      <PageHeader headingText="Startup Snapshot">
        <Text color="gray.600" pt={2} pb={4}>
          The easiest way to communicate your pitch to investors. Complete your
          Snapshot with your investment-relevant information and share with
          prospective investors via your private link for quick, easy decisions.
          You can track your snapshot views in{" "}
          <Link to="/engagement" as={ReachLink} color="blue.700">
            Engagement
          </Link>
          .
        </Text>

        <Text color="gray.600">
          <InfoIcon mr="6px" position="relative" top="-1px" />
          Remember, investors with your link will have access to your whole
          Snapshot, so only use information you are comfortable sharing and have
          the data to back up
        </Text>
      </PageHeader>

      <Box backgroundColor="gray.100" p={4} mb={4} borderRadius={4}>
        <Text fontSize={14} pb={1}>
          Use this permalink to share your Startup snapshot
        </Text>

        <Text>
          <Code colorScheme="yellow">
            <Link href={publicSnapshotHref} isExternal>
              {publicSnapshotHref}
            </Link>
          </Code>
        </Text>
      </Box>

      <Tabs index={selectedTabIndex} onChange={handleTabChange} variant="line">
        <TabList>
          <Tab>Basics</Tab>
          <Tab>Overview</Tab>
          <Tab>Team</Tab>
          <Tab>Metrics</Tab>
          <Tab>Funding</Tab>
          <Tab>Media</Tab>
          {startupData && (
            <Box ml="auto" px={4} py={2}>
              Your Snapshot is
              <strong> {startupData.profileCompletenessPercentage}% </strong>
              complete!
            </Box>
          )}
        </TabList>

        {queryStatus.loading && (
          <Box backgroundColor="gray.100">
            <Flex padding={20} align="center" justify="center">
              <Spinner size="xl" color="blue.700" />
            </Flex>
          </Box>
        )}

        {!queryStatus.loading && (
          <TabPanels>
            <TabPanel>
              <Box py={10} px={16} backgroundColor="gray.100">
                {startupData && (
                  <StartupSnapshotForm section="basics" startup={startupData} />
                )}
              </Box>
            </TabPanel>

            <TabPanel>
              <Box py={10} px={16} backgroundColor="gray.100">
                {startupData && (
                  <StartupSnapshotForm
                    section="overview"
                    startup={startupData}
                  />
                )}
              </Box>
            </TabPanel>

            <TabPanel>
              <Box py={10} px={16} backgroundColor="gray.100">
                {startupData && (
                  <StartupSnapshotForm section="team" startup={startupData} />
                )}
              </Box>
            </TabPanel>

            <TabPanel>
              <Box py={10} px={16} backgroundColor="gray.100">
                {startupData && (
                  <StartupSnapshotForm
                    section="metrics"
                    startup={startupData}
                  />
                )}
              </Box>
            </TabPanel>

            <TabPanel>
              <Box py={10} px={16} backgroundColor="gray.100">
                {startupData && (
                  <StartupSnapshotForm
                    section="funding"
                    startup={startupData}
                  />
                )}
              </Box>
            </TabPanel>

            <TabPanel>
              <Box py={10} px={16} backgroundColor="gray.100">
                {startupData && (
                  <StartupSnapshotForm section="media" startup={startupData} />
                )}
              </Box>
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
    </>
  )
}
