import * as React from "react"
import { RouteComponentProps, navigate } from "@reach/router"
import { useToast } from "@chakra-ui/react"

import { AuthenticationContext } from "../../contexts/Authentication"

export const Logout: React.FC<RouteComponentProps> = (props) => {
  // Grab the toasting utility
  const toast = useToast()

  // Grab the user sign out utility
  const { signOut } = React.useContext(AuthenticationContext)

  // Use an effect to sign out the user
  React.useEffect(() => {
    signOut()
    navigate("/login")

    // Show a toast
    toast({
      title: "We've signed you out",
      description: "Come back soon!",
      duration: 3000,
      isClosable: true,
    })
  }, [signOut, toast])

  // Return nothing so there isn't a flash of an empty page
  return null
}
