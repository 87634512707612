import * as React from "react"
import { RouteComponentProps, navigate, useLocation } from "@reach/router"
import { Box, Flex, Heading, Text, useToast } from "@chakra-ui/react"

import { EmailWallForm } from "./forms/EmailWallForm"
import Logo from "../assets/logo.svg"

export interface Props extends RouteComponentProps {
  component: React.FC<{ email: string } & any>
  formHeading: string
  formDescription: string
  successTitle: string
  successDescription: string
}

export const EmailWall: React.FC<Props> = ({
  component: Component,
  formHeading,
  formDescription,
  successTitle,
  successDescription,
  ...rest
}) => {
  const location = useLocation()

  // A utility for displaying toasts
  const toast = useToast()

  const existingUser = localStorage.getItem("currentUser")
  const authenticatedUser = existingUser ? JSON.parse(existingUser) : undefined

  const existingEmail = localStorage.getItem("currentEmail")

  const email = authenticatedUser ? authenticatedUser.user.email : existingEmail

  const [disableForm, setDisableForm] = React.useState<boolean>(false)

  // Handle when a new Investor is successfully registered
  const handleEmailCollected = async () => {
    // TODO: Remove this hack
    setDisableForm(true)
    await navigate(location.pathname)
  }

  // Handle when an error occured during Investor creation
  const handleError = () => {
    toast({
      title: "There was a problem collecting your email address",
      description: "Please try again",
      status: "warning",
      duration: 3000,
      isClosable: true,
    })
  }

  if (email) {
    // will use authenticated user's email or the email in local storage
    return <Component email={email} {...rest} />
  } else {
    return (
      <Box maxWidth="500px" margin="auto" px={6} padding={10}>
        <Flex align="center" justify="center" mb={2}>
          <img src={Logo} alt="Kaspa" width="120px" height="40px" />
        </Flex>

        <Box mb={12}>
          <Heading fontFamily="Roboto" textAlign="center" py={3}>
            {formHeading}
          </Heading>

          <Text fontSize={18} textAlign="center" color="gray.500">
            {formDescription}
          </Text>
        </Box>

        <Box rounded={4} boxShadow="0 5px 12px rgba(194,204,212,0.5)">
          <Box paddingX={6} paddingY={6}>
            <EmailWallForm
              isDisabled={disableForm}
              onLogin={handleEmailCollected}
              onError={handleError}
            />
          </Box>
        </Box>
      </Box>
    )
  }
}
