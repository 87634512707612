import * as React from "react"
import { useToast } from "@chakra-ui/react"
import ReactGA from "react-ga"

import { Basics } from "./startup-snapshot/Basics"
import { Overview } from "./startup-snapshot/Overview"
import { TeamMembers } from "./startup-snapshot/TeamMembers"
import { Metrics } from "./startup-snapshot/Metrics"
import { Funding } from "./startup-snapshot/Funding"
import { Media } from "./startup-snapshot/Media"

import { StartupSnapshotFields } from "../../graphql/types"

export interface Props {
  startup: StartupSnapshotFields
  section: "basics" | "overview" | "team" | "metrics" | "funding" | "media"
}

export const StartupSnapshotForm: React.FC<Props> = (props) => {
  // Util for popping toast
  const toast = useToast()

  // Handle when any of the forms report back success
  const onCommit = React.useCallback(() => {
    ReactGA.event({ category: "StartupSnapshot", action: "Updated" })

    toast({
      title: "Your snapshot has been updated!",
      description: "Make sure to keep it up-to-date for the highest impact",
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  }, [toast])

  // Handle when any of the forms report back a failure to commit
  const onError = React.useCallback(() => {
    toast({
      title: "There was a problem updating your snapshot",
      description:
        "Please resolve the issues on this tab and re-save it before moving on",
      status: "error",
      duration: 3000,
      isClosable: true,
    })
  }, [toast])

  // Decide which form we need to render!
  const FormComponent = React.useMemo(() => {
    switch (props.section) {
      case "basics":
        return Basics
      case "overview":
        return Overview
      case "team":
        return TeamMembers
      case "metrics":
        return Metrics
      case "funding":
        return Funding
      case "media":
        return Media
    }
  }, [props.section])

  // Render through to the relevant form
  return (
    <FormComponent
      startup={props.startup}
      onCommit={onCommit}
      onError={onError}
    />
  )
}
