import * as React from "react"
import { Box, Heading } from "@chakra-ui/react"

export interface Props {
  headingText: string
}

export const PageHeader: React.FC<Props> = props => {
  return (
    <Box paddingBottom={6}>
      <Heading as="h1" fontSize={26}>
        {props.headingText}
      </Heading>

      {props.children}
    </Box>
  )
}
