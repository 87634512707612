// Theming and global styles
import { extendTheme } from "@chakra-ui/react"

// Override some react-grid-system settings
import { setConfiguration } from "react-grid-system"

// Override what we want in the Chakra Theme
export const customTheme = extendTheme({
  fonts: {
    heading: "'Montserrat', sans-serif",
    body: "'Roboto', sans-serif",
  },
  fontWeights: {
    semibold: 500,
  },
  colors: {
    gray: {
      800: "#273745",
    },
    blue: {
      50: "#6bd3f2",
      100: "#5ac8ef",
      200: "#48bdec",
      300: "#36b2e9",
      400: "#129ce2",
      500: "#0091DF",
      600: "#0084d0",
      700: "#0076c0",
      800: "#0069b1",
      900: "#005ba2",
    },
    twitter: "#1da1f2",
    facebook: "#3b5998",
    linkedin: "#0077b5",
  },
})

// Set grid options
// Note: this is not theming per-se but it affects global styling so
// this seems like a sensible file to put this in
setConfiguration({
  gutterWidth: 10,
})
