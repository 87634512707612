import gql from "graphql-tag"
import { AuthenticatedStartupUser } from "../types"

export interface AcceptInvitationCode {
  invitationCode: string
  password: string
}

export interface Data {
  acceptInvitation: AuthenticatedStartupUser
}

export interface Variables {
  data: AcceptInvitationCode
}

export const ACCEPT_INVITATION = gql`
  mutation acceptInvitation($data: AcceptInvitationInput!) {
    acceptInvitation(data: $data) {
      user {
        id
        givenName
        familyName
        email
        insertedAt
        startup {
          id
          slug
          name
          locationCountry
        }
      }
      session {
        token
      }
    }
  }
`