import * as React from "react"
import { Router, globalHistory } from "@reach/router"
import ReactGA from "react-ga"

import { ScrollToTop } from "./ScrollToTop"
import { Authenticated } from "./Authenticated"
import { EmailWall } from "./EmailWall"
import { Layout } from "./Layout"

// Set up Intercom for the entire app
import { IntercomProvider } from "./IntercomProvider"

import { Register } from "./pages/Register"
import { Login } from "./pages/Login"
import { PublicStartupSnapshot } from "./pages/PublicStartupSnapshot"

import { AcceptInvitation } from "./pages/AcceptInvitation/AcceptInvitation"
import { LoginStartup } from "./pages/LoginStartup"

import { Dashboard } from "./pages/Dashboard"
import { Matchbook } from "./pages/Matchbook"
import { DataRoom } from "./pages/DataRoom"
import { Snapshot } from "./pages/Snapshot"
import { Engagement } from "./pages/Engagement"

import { Logout } from "./pages/Logout"

const INTERCOM_APP_ID =
  process.env.NODE_ENV === "production" ? "iboqn479" : "csfo0nrh"

export const Routes: React.FC = (props) => {
  React.useEffect(() => {
    return globalHistory.listen(({ location }) => {
      ReactGA.pageview(location.pathname + location.search)
    })
  }, [])

  const publicStartupSnapshotEmailWallProps = {
    formHeading: "Please provide your email address to continue",
    formDescription: "This Snapshot is private",
    successTitle: "Thank you!",
    successDescription:
      "Providing your email address lets startups know you are interested",
  }

  return (
    <Router>
      <IntercomProvider appId={INTERCOM_APP_ID} path="/">
        <ScrollToTop path="/">
          {/* Public routes */}
          <Register path="/register" />
          <Login path="/login" />
          <EmailWall
            path="/startup/:slug"
            component={PublicStartupSnapshot}
            {...publicStartupSnapshotEmailWallProps}
          />
          <EmailWall
            path="/startup/:slug/:tab"
            component={PublicStartupSnapshot}
            {...publicStartupSnapshotEmailWallProps}
          />
          <AcceptInvitation path="/invitation/startup" />
          <AcceptInvitation path="/invitation/startup/:invitationCode" />
          <LoginStartup path="/login/startup" />
          {/* Authenticated routes */}
          <Authenticated path="/">
            {/*
              TODO: Arrange for Authenticated to take a render prop that guarantees an authenticated user
              is passed down to the pages below.
              Alternatively provide a sub context that ia guaranteed to provide an authenicated user object
             */}
            <Layout path="/">
              <Dashboard path="/" />
              <Matchbook path="matchbook" />
              <DataRoom path="data-room" />

              <Snapshot path="/snapshot" />
              <Snapshot path="/snapshot/:section" />
              <Engagement path="/engagement" />
            </Layout>
          </Authenticated>
          {/* Logout path */}
          <Logout path="/logout" />
        </ScrollToTop>
      </IntercomProvider>
    </Router>
  )
}
