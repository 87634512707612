import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  LinkProps,
  Link,
} from "@chakra-ui/react"
import * as React from "react"
import { FaLinkedinIn } from "react-icons/fa"

export interface TeamMemberProps {
  name: string | null
  role: string | null
  skills: string[] | null
  description: string | null
  linkedinUrl: string | null
}

export const TeamMember = (props: TeamMemberProps) => {
  const { role, name, linkedinUrl, description } = props
  return (
    <Flex
      direction="column"
      bg={useColorModeValue("white", "gray.700")}
      shadow={useColorModeValue("base", "none")}>
      <Box p="6" flex="1">
        <Box mt="2">
          <Text fontWeight="bold">{name}</Text>
          <Text
            color={useColorModeValue("gray.500", "whiteAlpha.700")}
            fontWeight="medium"
            fontSize="sm">
            {role}
          </Text>
        </Box>
        <Text color={useColorModeValue("gray.600", "whiteAlpha.800")} mt="6">
          {description}
        </Text>
      </Box>
      <Flex align="center" spacing="5" borderTopWidth="1px">
        {linkedinUrl && (
          <SocialLink icon={FaLinkedinIn} color="linkedin" href={linkedinUrl}>
            LinkedIn
          </SocialLink>
        )}
      </Flex>
    </Flex>
  )
}

interface SocialProps extends LinkProps {
  href?: string
  children: React.ReactNode
  color: string
  icon: React.ElementType
}

const SocialLink = (props: SocialProps) => {
  const { href, children, color, icon: SocialIcon, ...rest } = props
  return (
    <Link
      flex="1"
      display="flex"
      py="3"
      alignItems="center"
      justifyContent="center"
      fontWeight="bold"
      fontSize="sm"
      isExternal
      color={color}
      href={href}
      _hover={{ bg: useColorModeValue("gray.50", "gray.700") }}
      {...rest}>
      <SocialIcon aria-hidden />
      <Text ms="2">{children}</Text>
    </Link>
  )
}
