import * as React from "react"
import { useInvestor } from "../../hooks/useInvestor"
import { FiArchive, FiMapPin, FiUser } from "react-icons/fi"
import { ExternalLinkIcon, RepeatIcon } from "@chakra-ui/icons"

import {
  Avatar,
  Button,
  Box,
  Heading,
  Icon,
  Flex,
  Link,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  Text,
} from "@chakra-ui/react"

export interface Props {
  investor: {
    id: string
  }
}

export const InvestorArticle: React.FC<Props> = (props) => {
  // Reach out to the API and fetch the specified investor
  const [investor, { error, loading, refetch }] = useInvestor(props.investor.id)

  // Render a loading indicator if the query is still in-flight
  if (loading) {
    return (
      <Flex height="100%" align="center" justify="center">
        <Spinner color="blue.400" size="xl" />
      </Flex>
    )
  }

  // Render an error message if there was a problem with the query
  if (error) {
    return (
      <Flex height="70%" direction="column" align="center" justify="center">
        <Text fontSize={20} pb={4} color="red.500">
          Sorry, but there was a problem retrieving that Investor.
        </Text>

        <Button onClick={() => refetch()} leftIcon={<RepeatIcon />}>
          Click here to try again
        </Button>
      </Flex>
    )
  }

  // If we successfully retrieved the Investor, render their details!
  if (investor) {
    return (
      <Box padding={8}>
        <Flex direction="column" align="center">
          <Avatar
            size="xl"
            mb={4}
            name={investor.name}
            src={investor.logoUrl || ""}
          />

          <Heading fontSize={26} mb={1}>
            {investor.name}
          </Heading>

          {investor.websiteUrl !== null && (
            <Link color="blue.400" href={investor.websiteUrl} isExternal>
              <ExternalLinkIcon mx="2px" position="relative" top="-1.5px" />
              Visit Website
            </Link>
          )}
        </Flex>

        <Flex justify="center" pt={8} pb={6}>
          <Flex direction="column" align="center" px={2}>
            <Icon as={FiMapPin} size="24px" color="blue.100" mb={1} />
            <Text fontSize={20} mt={1} textAlign="center">
              {investor.locationCity}, {investor.locationCountry}
            </Text>
          </Flex>

          {investor.categories.length > 0 && (
            <Flex direction="column" align="center" px={2}>
              <Icon as={FiArchive} size="24px" color="blue.100" mb={1} />
              <Text fontSize={20} mt={1} textAlign="center">
                {investor.categories.join(", ")}
              </Text>
            </Flex>
          )}
        </Flex>

        {investor.description && (
          <Box backgroundColor="gray.50" padding={4} borderRadius={4}>
            <Text fontSize={16}>{investor.description}</Text>
          </Box>
        )}

        {investor.portfolio && (
          <Box mt={4}>
            <Text>Notable portfolio</Text>
            <Text fontSize={16}>{investor.portfolio}</Text>
          </Box>
        )}

        {investor.contacts.length > 0 && (
          <Box mt={4}>
            <Text pb={2}>Contacts</Text>

            {investor.contacts.map((contact, index) => {
              return (
                <Box pb={2} key={index}>
                  <Text>
                    <Icon as={FiUser} mr={1} position="relative" top="-2px" />
                    <strong>{contact.name}</strong>
                    <Text fontSize={12} as="span">
                      {contact.title ? `, ${contact.title}` : null}
                    </Text>
                  </Text>

                  <Text fontSize={14} pl={5}>
                    <Link href={`mailto:${contact.email}`} isExternal>
                      {contact.email}
                    </Link>
                  </Text>

                  {contact.linkedinUrl && (
                    <Text fontSize={14} pl={5} textDecoration="underline">
                      <Link href={contact.linkedinUrl} isExternal>
                        LinkedIn Profile
                      </Link>
                    </Text>
                  )}
                </Box>
              )
            })}
          </Box>
        )}

        {investor.geographicPreferences.length > 0 && (
          <Box mt={4}>
            <Text pb={2}>Geographic preferences</Text>
            {investor.geographicPreferences.map((pref, index) => {
              return (
                <Tag size="md" mr={2} mb={2} key={index}>
                  {[pref.continent, pref.country, pref.region].join(" ").trim()}
                </Tag>
              )
            })}
          </Box>
        )}

        {investor.fundSize && (
          <Stat mt={4}>
            <StatLabel>Size of fund</StatLabel>
            <StatNumber fontSize={16}>{investor.fundSize}</StatNumber>
          </Stat>
        )}

        {investor.investmentRange && (
          <Stat mt={2}>
            <StatLabel>Investment Range</StatLabel>
            <StatNumber fontSize={16}>{investor.investmentRange}</StatNumber>
          </Stat>
        )}

        {investor.industryPreferences.length > 0 && (
          <Box pt={4}>
            <Text mb={2}>Industry Preferences</Text>
            {investor.industryPreferences.map((pref) => (
              <Tag size="md" mr={2} mb={2} key={pref}>
                {pref}
              </Tag>
            ))}
          </Box>
        )}

        {investor.verticalPreferences.length > 0 && (
          <Box pt={2}>
            <Text mb={2}>Vertical Preferences</Text>
            {investor.verticalPreferences.map((pref) => (
              <Tag size="md" mr={2} mb={2} key={pref}>
                {pref}
              </Tag>
            ))}
          </Box>
        )}

        {investor.customerFocusPreferences.length > 0 && (
          <Box pt={2}>
            <Text mb={2}>Customer Focus Preferences</Text>
            {investor.customerFocusPreferences.map((pref) => (
              <Tag size="md" mr={2} mb={2} key={pref}>
                {pref}
              </Tag>
            ))}
          </Box>
        )}
      </Box>
    )
  }

  return null
}
