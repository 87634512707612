import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { Text } from "@chakra-ui/react"

import { PageHeader } from "../PageHeader"

export interface Props extends RouteComponentProps {
  section?: string
}

export const InvestorProfile: React.FC<Props> = (props) => {
  return (
    <PageHeader headingText="Investor Profile">
      <Text color="gray.600" pt={2}>
        The best way to get started on Kaspa is to complete your profile. This
        provides the most visibility in to your Investments and will attract the
        most Startups
      </Text>
    </PageHeader>
  )
}
