import * as React from "react"
import { Link } from "@reach/router"
import { Box, Flex, Heading, Text, useToast } from "@chakra-ui/react"

import { AuthenticatedStartupUser } from "@app/graphql/types"
import { AcceptInvitationForm } from "@app/components/forms/AcceptInvitationForm"

import Logo from "@assets/logo.svg"

type HandleAcceptInvitationCallback = (
  startup: AuthenticatedStartupUser
) => Promise<void>

export interface Props {
  handleAcceptInvitation: HandleAcceptInvitationCallback
  handleStartupError: () => Promise<void>
  invitationCode?: string
}

export const AcceptInvitationBasic: React.FC<Props> = (props) => {
  const toast = useToast()

  const handleAcceptInvitation = React.useMemo<HandleAcceptInvitationCallback>(
    () => (startup: AuthenticatedStartupUser) => {
      return props.handleAcceptInvitation(startup).then(() => {
        toast({
          title: "Welcome to Kaspa!",
          description: "Our community of Investors can't wait to meet you...",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        return Promise.resolve(void 0)
      })
    },
    [props, toast]
  )

  const handleStartupError = React.useMemo<() => Promise<void>>(
    () => () => {
      return props.handleStartupError().then(() => {
        toast({
          title: "There was a problem accepting your invite",
          description: "Please try again",
          status: "warning",
          duration: 3000,
          isClosable: true,
        })
        return Promise.resolve(void 0)
      })
    },
    [props, toast]
  )

  return (
    <Box maxWidth="500px" margin="auto" px={6} py={10}>
      <Flex align="center" justify="center" mb={2}>
        <img src={Logo} alt="Kaspa" width="120px" height="40px" />
      </Flex>

      <Box mb={12}>
        <Heading fontFamily="Roboto" textAlign="center" py={3}>
          Create Your Account
        </Heading>

        <Text fontSize={18} textAlign="center" color="gray.500">
          Let's make this your easiest capital raise yet.
        </Text>
      </Box>

      <Box rounded={4} boxShadow="0 5px 12px rgba(194,204,212,0.5)">
        <Box paddingX={4} paddingY={6}>
          <AcceptInvitationForm
            invitationCode={props.invitationCode}
            onAccept={handleAcceptInvitation}
            onError={handleStartupError}
          />
        </Box>
      </Box>

      <Flex direction="column" align="center" justify="center" pt={6} pb={24}>
        <Text fontWeight="medium" paddingTop={4}>
          Already have an account?
        </Text>

        <Text>
          <Link to="/login/startup">
            Sign in here{" "}
            <Text as="span" paddingLeft={0.5} color="blue.500">
              →
            </Text>
          </Link>
        </Text>
      </Flex>
    </Box>
  )
}
