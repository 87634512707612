import * as React from "react"
import { Divider, Button, ButtonGroup, Text } from "@chakra-ui/react"
import { Form, Formik, FormikHelpers, FormikProps, Field } from "formik"

import { TextareaInput } from "../inputs/TextareaInput"
import { useUpdateStartup } from "../../../hooks/useUpdateStartup"
import { applyAbsintheErrors } from "../../../utilities/forms"

export interface FormValues {
  problemToSolve: string | null
  problemSolution: string | null
  marketDescription: string | null
  valueProposition: string | null
  businessModel: string | null
  competitionDescription: string | null
}

export interface Props {
  startup: FormValues
  onCommit: () => void
  onError: () => void
}

export type FormUtils = FormikHelpers<FormValues>

export const Overview: React.FC<Props> = (props) => {
  // Prepare mutation for updating over the API
  const [updateStartup] = useUpdateStartup()

  // Set up the initial values for the form
  const initialValues: FormValues = React.useMemo(() => {
    return {
      problemToSolve: props.startup.problemToSolve,
      problemSolution: props.startup.problemSolution,
      marketDescription: props.startup.marketDescription,
      valueProposition: props.startup.valueProposition,
      businessModel: props.startup.businessModel,
      competitionDescription: props.startup.competitionDescription,
    }
  }, [props.startup])

  // Set up the submission handler
  const onSubmit = async (values: FormValues, form: FormUtils) => {
    // Build the mutation's parameteres
    const params = {
      variables: {
        data: values,
      },
    }

    // Fire off the mutation and take action accordingly based on the result
    return updateStartup(params)
      .then((result) => {
        if (result.data?.updateStartup) {
          props.onCommit()
        }
      })
      .catch((errorResponse) => {
        props.onError()
        applyAbsintheErrors(errorResponse, form)
      })
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(form: FormikProps<FormValues>) => {
        return (
          <Form>
            <Text pb={4}>
              Let’s dig a little deeper now. In no more than a few sentences per
              section, break down your pitch.
            </Text>

            <Field
              name="problemToSolve"
              label="What is the problem you’re solving?"
              maxLength={400}
              component={TextareaInput}
            />
            <Field
              name="problemSolution"
              label="What is your unique solution?"
              maxLength={400}
              component={TextareaInput}
            />
            <Field
              name="marketDescription"
              label="What does your market look like and how big is it?"
              maxLength={400}
              component={TextareaInput}
            />
            <Field
              name="valueProposition"
              label="What is your unique value proposition which will allow you to win?"
              maxLength={400}
              component={TextareaInput}
            />
            <Field
              name="businessModel"
              label="What is your business model?"
              maxLength={400}
              component={TextareaInput}
            />
            <Field
              name="competitionDescription"
              label="What does your competition look like?"
              maxLength={400}
              component={TextareaInput}
            />

            <Divider my={8} borderColor="gray.300" />

            <ButtonGroup spacing={4}>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={form.isSubmitting}>
                Update Snapshot
              </Button>

              <Button
                type="reset"
                variant="ghost"
                colorScheme="red"
                isDisabled={form.isSubmitting}>
                Reset
              </Button>
            </ButtonGroup>
          </Form>
        )
      }}
    </Formik>
  )
}
