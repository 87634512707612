import { useQuery } from "@apollo/react-hooks"
import { QueryResult } from "react-apollo"
import { InvestorWhereInput } from "../graphql/types"

import gql from "graphql-tag"

export type QueriedInvestor = {
  id: string
  name: string
  logoUrl: string
  locationCity: string
  category: string
  customerFocusPreferences: string[]
  industryPreferences: string[]
  verticalPreferences: string[]
  stagePreferences: string[]
  introUrl: string | null
}

export const INVESTORS = gql`
  query investors($where: InvestorWhereInput) {
    investors(where: $where) {
      id
      name
      logoUrl
      locationCity
      customerFocusPreferences
      industryPreferences
      verticalPreferences
      stagePreferences
      introUrl
    }
  }
`

export interface Data {
  investors: QueriedInvestor[]
}

export interface Variables {
  where?: InvestorWhereInput
}

export type UseInvestorsHookResult = [
  Data["investors"] | undefined | null,
  QueryResult<Data, Variables>
]

export type UseInvestorsHook = (
  where?: InvestorWhereInput
) => UseInvestorsHookResult

export const useInvestors: UseInvestorsHook = where => {
  // Run the query
  const query = useQuery<Data, Variables>(INVESTORS, {
    notifyOnNetworkStatusChange: true,
    variables: { where: where },
  })

  // Return the result as an 'unwrapped' tuple
  return [query.data?.investors, query]
}
