import * as React from "react"
import { RouteComponentProps, navigate } from "@reach/router"

import { LoginBasic } from "./LoginBasic"

export const Login: React.FC<RouteComponentProps> = (props) => {
  // Keep a piece of state to control which LargeSelectionTile is active
  const [activeTile, setActiveTile] = React.useState<
    "investor" | "startup" | "none"
  >("none")

  // Handle when the Investor tile is clicked
  const handleInvestorClick = () => {
    setActiveTile("investor")
  }

  // Handle when the Startup tile is clicked
  const handleStartupClick = () => {
    setActiveTile("startup")
  }

  // Handle when the Investor tile "continue" button is clicked
  const handleInvestorContinue = () => {
    navigate("/login/investor")
  }

  // Handle when the Startup tile "continue" button is clicked
  const handleStartupContinue = () => {
    navigate("/login/startup")
  }

  return (
    <LoginBasic
      activeTile={activeTile}
      handleInvestorClick={handleInvestorClick}
      handleStartupClick={handleStartupClick}
      handleInvestorContinue={handleInvestorContinue}
      handleStartupContinue={handleStartupContinue}
    />
  )
}
