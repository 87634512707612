import * as React from "react"
import { RouteComponentProps, navigate } from "@reach/router"
import { Flex, Box, Heading, Stack, Text } from "@chakra-ui/react"
import { Hidden, Visible } from "react-grid-system"
import { motion } from "framer-motion"

import { LargeSelectionTile } from "../ui/LargeSelectionTile"

import LogoWhite from "../../assets/logo-white.svg"
import LogoBlue from "../../assets/logo.svg"
import Coins from "../../assets/coins.svg"
import Unicorn from "../../assets/unicorn.svg"

export const Register: React.FC<RouteComponentProps> = (props) => {
  // Keep a piece of state to control which LargeSelectionTile is active
  const [activeTile, setActiveTile] = React.useState<
    "investor" | "startup" | undefined
  >()

  // Handle when the Investor tile is clicked
  const handleInvestorClick = () => {
    setActiveTile("investor")
  }

  // Handle when the Startup tile is clicked
  const handleStartupClick = () => {
    setActiveTile("startup")
  }

  // Handle when the Investor tile "continue" button is clicked
  const handleInvestorContinue = () => {
    navigate("/register/investor")
  }

  // Handle when the Startup tile "continue" button is clicked
  const handleStartupContinue = () => {
    navigate("/register/startup")
  }

  return (
    <Flex height="100vh">
      <Hidden xs sm md>
        <Box padding={16} width="30%" height="100vh" backgroundColor="blue.500">
          <Box pb="100px">
            <img src={LogoWhite} alt="Kaspa" width="120px" height="40px" />
          </Box>

          <Text fontSize={32} pb={12} pr={12} lineHeight="40px" color="white">
            <strong>
              You’ve never been so close to finding your perfect partner.
            </strong>
          </Text>

          <Text fontSize={20} pr={12} lineHeight="32px" color="blue.100">
            Find investors or startups and automate the funding process in
            minutes
          </Text>
        </Box>
      </Hidden>

      <Box width={{ base: "100%", lg: "70%" }} padding={80} paddingTop="16vh">
        <Visible xs sm md>
          <Box pb={6}>
            <motion.div initial={{ y: -12 }} animate={{ y: 0 }}>
              <img src={LogoBlue} alt="Kaspa" width={149} height={41} />
            </motion.div>
          </Box>
        </Visible>

        <Box pb={16}>
          <Heading fontFamily="Roboto" fontSize="2rem" pb={4} color="gray.800">
            Are you an Investor or a Startup?
          </Heading>

          <Text fontSize={18} maxWidth="480px" color="gray.400">
            You’re just two steps away from finding your dream investor or your
            perfectly matched startup founder
          </Text>
        </Box>

        <Stack maxWidth="530px" spacing={8}>
          <LargeSelectionTile
            isActive={activeTile === "investor"}
            icon={Coins}
            onClick={handleInvestorClick}
            onContinueClick={handleInvestorContinue}
            primaryText="Investor"
            subText="I'm looking for startups"
          />

          <LargeSelectionTile
            isDisabled
            isActive={activeTile === "startup"}
            icon={Unicorn}
            onClick={handleStartupClick}
            onContinueClick={handleStartupContinue}
            primaryText="Startup"
            subText="I'm looking for investors"
          />
        </Stack>
      </Box>
    </Flex>
  )
}
