import * as React from "react"
import { Icon, ListItem, UnorderedList } from "@chakra-ui/react"
import { SnapshotField } from "../../ui/PublicSnapshot/SnapshotField"
import { FiExternalLink } from "react-icons/fi"

interface StartupProps {
  pressAndAwardsUrls: string[] | null
}
export interface Props {
  startup: StartupProps
}

/**
 * Determines whether or not the Media tab should be shown on the main
 * public Snapshot profile. If changing what fields are shown in the Media FC,
 * please update this function as well!!
 */
export const showMediaTab = (startup: StartupProps) => {
  return (startup.pressAndAwardsUrls || []).length > 0
}

export const Media: React.FC<Props> = (props) => {
  // Pick off the startup from props
  const { startup } = props

  return (
    <React.Fragment>
      {startup.pressAndAwardsUrls && startup.pressAndAwardsUrls.length >= 1 && (
        <SnapshotField
          title="Press and Awards"
          value={startup.pressAndAwardsUrls[0]}>
          <UnorderedList>
            {startup.pressAndAwardsUrls.map((link, index) => {
              return (
                <ListItem>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {link}
                  </a>
                  <Icon as={FiExternalLink} ml={1} />
                </ListItem>
              )
            })}
          </UnorderedList>
        </SnapshotField>
      )}
    </React.Fragment>
  )
}
