import * as React from "react"

export const CloudinaryContext = React.createContext({
  currentEnvironment: "",
  cloudName: "",
  presetName: "",
})

export const CloudinaryProvider: React.FC = (props) => {
  const currentEnvironment = React.useMemo(
    () =>
      process.env.NODE_ENV === "development"
        ? "development"
        : process.env.REACT_APP_ENV || "production",
    []
  )
  const cloudName = React.useMemo(
    () =>
      currentEnvironment === "development"
        ? "alembic-testing"
        : "diligr-pty-ltd",
    [currentEnvironment]
  )

  const presetName = "n2h2ryav"

  return (
    <CloudinaryContext.Provider
      value={{
        currentEnvironment,
        cloudName,
        presetName,
      }}>
      {props.children}
    </CloudinaryContext.Provider>
  )
}
