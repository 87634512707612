import * as React from "react"
import { Flex, Avatar, AvatarBadge, Text } from "@chakra-ui/react"

import { AuthenticationContext } from "../contexts/Authentication"
import { InvestorUser, StartupUser } from "../graphql/types"

export const CurrentUserDropdown: React.FC = (props) => {
  const { authState } = React.useContext(AuthenticationContext)

  switch (authState.name) {
    case "AUTHENTICATED": {
      const fullName = `${authState.authedUser.user.givenName} ${authState.authedUser.user.familyName}`
      let companyName =
        authState.userKind === "startup_user"
          ? (authState.authedUser.user as StartupUser).startup.name
          : (authState.authedUser.user as InvestorUser).investor.name

      return (
        <Flex align="center">
          <Avatar name={fullName} src="">
            <AvatarBadge size="1em" bg="green.500" />
          </Avatar>

          <Flex pl={3} justify="center" direction="column">
            <Text fontSize={18}>
              <strong>{fullName}</strong>
            </Text>

            <Text fontSize={16} color="gray.600">
              {companyName}
            </Text>
          </Flex>
        </Flex>
      )
    }
    default:
      return null
  }
}
