import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { NetworkStatus } from "@apollo/client"
import { Container, Row, Col } from "react-grid-system"
import { Avatar, Box, Button, Text } from "@chakra-ui/react"
import { InfoIcon, ViewIcon } from "@chakra-ui/icons"

import { PageHeader } from "../PageHeader"
import { DrawerOverlay } from "../ui/DrawerOverlay"

import { InvestorArticle } from "../investors/InvestorArticle"
import { InvestorSearchFilters } from "../ui/InvestorSearchFilters"
import { useInvestors, QueriedInvestor } from "../../hooks/useInvestors"
import { AuthenticationContext } from "../../contexts/Authentication"
import { StartupUser } from "../../graphql/types"

export const Matchbook: React.FC<RouteComponentProps> = props => {
  const { authState } = React.useContext(AuthenticationContext)
  // FIXME this is a horrible hack.
  // I think the fix is to have a sub-context when we have an authenticated user so that we can
  // avoid this auth-check dance.
  const startupCountry = ((authState as any).authedUser.user as StartupUser)
    .startup.locationCountry
  const defaultFilters = startupCountry
    ? { geographicPreference: startupCountry }
    : {}

  // Reach out the API and grab a list of all Investors on which we can filter
  const [investors, { refetch, networkStatus }] = useInvestors(defaultFilters)

  // Keep a piece of state which tracks the selected Investor ID
  const [selectedInvestor, setSelectedInvestor] = React.useState<
    QueriedInvestor | undefined
  >()

  // Handle when the View button of an Investor is clicked
  const handleViewClick = (investor: QueriedInvestor) => {
    setSelectedInvestor(investor)
  }

  // Handle when the Request Intro button of an Investor is clicked
  const handleIntroClick = (investor: QueriedInvestor) => {
    if (investor.introUrl) {
      const tab = window.open(investor.introUrl, "_blank")
      tab?.focus()
    }
  }

  // Handle when the Drawer should be closed
  const handleDrawerClose = () => {
    setSelectedInvestor(undefined)
  }

  // Handle when new filters are selected by reaching out to the server for
  // a new set of investors
  const handleFilterSelectionUpdate = React.useCallback(
    filters => refetch({ where: filters }),
    [refetch]
  )

  const loading = networkStatus === NetworkStatus.loading

  return (
    <React.Fragment>
      <Box paddingBottom={6}>
        <PageHeader headingText="Matchbook" />

        <Text pb={4}>
          Find the investors best suited to you. Get your curated list of
          investors by inputting your startup criteria to get an instant
          shortlist of the best matches for your company.
        </Text>

        <Text color="gray.500">
          <InfoIcon mr="4px" position="relative" top="-1px" /> Many investors
          will be generalists, meaning they will invest in most verticals. If
          you would like to include these in your search, please include the
          vertical ‘any’ in your vertical filter, plus your specific verticals.
        </Text>
      </Box>

      {/* Display a loading indicator if the initial queries are still in-flight */}
      {loading && <p>Loading</p>}
      {!loading && (
        <>
          <InvestorSearchFilters
            onSelectionUpdate={handleFilterSelectionUpdate}
            isDisabled={networkStatus === NetworkStatus.refetch}
          />

          {/* Render a slide-in Drawer that displays the selected Investor detail */}
          {selectedInvestor !== undefined && (
            <DrawerOverlay onClose={handleDrawerClose} isVisible={true}>
              <InvestorArticle investor={selectedInvestor} />
            </DrawerOverlay>
          )}

          {/* If we have a list of investors, display them! */}
          <div>
            <Box background="white">
              <Container fluid>
                {investors && investors.length <= 0 && (
                  <Text fontSize={22} py={12} px={4} textAlign="center">
                    There are no Investors matching your search criteria
                  </Text>
                )}

                {investors &&
                  investors.length >= 1 &&
                  investors.map(investor => (
                    <React.Fragment key={investor.id}>
                      <Box
                        padding={8}
                        borderBottom="solid 1px"
                        borderColor="gray.50">
                        <Row key={investor.id} align="center" style={{display: 'flex'}}>
                          <Col sm={1}>
                            <Avatar
                              name={investor.name}
                              src={investor.logoUrl}
                            />
                          </Col>

                          <Col>
                            <Text fontSize={12} color="gray.400">
                              Name
                            </Text>

                            <Text fontSize={18}>
                              <strong>{investor.name}</strong>
                            </Text>
                          </Col>

                          {/*
                            HACK: The following flexGrow adjustment is to ensure alignment of the City
                            column when the optional investor URL is present or not. If the rows were
                            laid out using a real CSS Grid this issue would go away. Let's fix this after
                            MVP.
                           */}
                          <Col style={{flexGrow: !investor.introUrl ? 2 : 1}}>
                            <Text fontSize={12} color="gray.400">
                              City
                            </Text>
                            <Text>{investor.locationCity}</Text>
                          </Col>

                          <Col style={{ textAlign: "right" }}>
                            <Button
                              onClick={() => handleViewClick(investor)}
                              leftIcon={
                                <ViewIcon position="relative" top="-1px" />
                              }>
                              View
                            </Button>
                          </Col>

                          {investor.introUrl && (
                            <Col style={{ textAlign: "right" }}>
                              <Button
                                onClick={() => handleIntroClick(investor)}
                                leftIcon={<ViewIcon />}>
                                Request Intro
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </Box>
                    </React.Fragment>
                  ))}
              </Container>
            </Box>
          </div>
        </>
      )}
    </React.Fragment>
  )
}
