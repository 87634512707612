import * as React from "react"
import { Box, Text } from "@chakra-ui/react"
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"

import { usePublicStartupSnapshotViewings } from "@app/hooks/usePublicStartupSnapshotViewings"

export interface Props {
  startup_id: string
}

export const PublicStartupSnapshotViewings: React.FC<Props> = (props) => {
  const [viewings, query] = usePublicStartupSnapshotViewings({
    startup_id: props.startup_id,
  })

  return (
    <React.Fragment>
      <Text fontSize={22} pb={4} fontWeight="bold" color="gray.700">
        My Snapshot Engagement
      </Text>

      <Box overflowY="auto" maxH="300px">
        {query.error && (
          <Text bgColor="red.100" color="red">
            Something went wrong...we couldn't load any data
          </Text>
        )}
        {query.loading && <p>Loading!</p>}
        {!query.loading && !query.error && (
          <React.Fragment>
            {viewings && viewings.length >= 1 && (
              <Table variant="simple" bg="white">
                <Thead>
                  <Tr>
                    <Th position="sticky" top="0" bg="white">
                      Email address of viewer
                    </Th>
                    <Th position="sticky" top="0" bg="white">
                      Date viewed
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {viewings.map((viewing) => (
                    <Tr key={viewing.id}>
                      <Td>{viewing.email}</Td>
                      <Td><FormattedDate date={viewing.inserted_at} /></Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
            {(!viewings || viewings.length === 0) && (
              <Text>You don't have any viewings...yet!</Text>
            )}
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  )
}

const FormattedDate: React.FC<{date: string}> = ({date}) =>
  {

    const dateString = new Date(date).toDateString()
    const diffString = (date: string) => {
      const nowDate = new Date(Date.now())
      const viewDate = new Date(date)
      if (nowDate.getDate() === viewDate.getDate()) {
        const hours = Math.floor((nowDate.getTime() - viewDate.getTime())/(1000*60*60))
        if (hours < 1) {
return "less than an hour ago"
        } else if (hours === 1) {
          return ""+hours+" hour ago"
        }
        else {
          return ""+hours+" hours ago"
        }
      }
      else {
        return undefined
      }
    }
    const diff = diffString(date)

    return (
      <React.Fragment>
      <Text>{dateString}</Text>
      {diff && (
        <Text fontSize={12} color="gray.500">{diff}</Text>
      )}
      </React.Fragment>
    )



  }
