import * as React from "react"
import Select from "react-select"

import type { DropdownOption} from '../option'

export interface Props {
  options: ReadonlyArray<DropdownOption>
  isDisabled?: boolean
  onChange: (value: DropdownOption['value'] | null) => void
  defaultValue?: DropdownOption | null
  placeholder: string
}

export const SingleSelectionDropdown: React.FC<Props> = (props) => {
  const { onChange, defaultValue, options } = props

  const handleChange = React.useCallback<(selected: DropdownOption | null) => void>(
    selected => {
      if (selected !== null) {
        onChange(selected.value)
      } else {
        onChange(null)
      }
    },
    [onChange]
  )

  return (
    <Select<DropdownOption, false>
      width="100%"
      onChange={handleChange}
      isClearable={true}
      closeMenuOnSelect={true}
      placeholder={props.placeholder}
      defaultValue={defaultValue}
      options={options}
      isMulti={false}
      isDisabled={props.isDisabled}
    />
  )
}
