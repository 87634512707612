import * as React from "react"
import { Flex, Box } from "@chakra-ui/react"
import { RouteComponentProps } from "@reach/router"

import { Sidebar } from "./Sidebar"

export const Layout: React.FC<RouteComponentProps> = (props) => {
  return (
    <>
      <Flex>
        <Sidebar />

        <Box paddingLeft={260} flex={1}>
          <Box flex={1} backgroundColor="gray.50" minHeight="100vh">
            <Box paddingY={12} paddingX={16}>
              {props.children}
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  )
}
