import * as React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { motion } from "framer-motion"

export interface Props {
  onClose: () => void
  isVisible: boolean
}

export const DrawerOverlay: React.FC<Props> = props => {
  // Handle when the background of the overlay is clicked
  const handleBackgroundClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    props.onClose()
  }

  return (
    <Box
      onClick={handleBackgroundClick}
      display={props.isVisible ? "block" : "none"}
      position="fixed"
      top="0"
      left="0"
      height="100vh"
      width="100vw"
      zIndex={10}>
      <Flex
        height="100vh"
        justify="flex-end"
        background="rgba(0,0,0,0.1)"
        padding="32px"
        overflow="hidden">
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}>
          <Box
            width="100%"
            maxWidth="30vw"
            minWidth="30vw"
            height="100%"
            borderRadius="4px"
            boxShadow="0 4px 10px rgba(0,0,0,0.05)"
            background="white"
            overflowY="scroll"
            zIndex={100}>
            {props.children}
          </Box>
        </motion.div>
      </Flex>
    </Box>
  )
}
