import * as React from "react"
import { Link, LinkGetProps } from "@reach/router"
import {
  MdAccountCircle,
  MdDashboard,
  MdExitToApp,
  MdGroup,
  MdPageview,
} from "react-icons/md"

import {
  Box,
  BoxProps,
  Divider,
  Flex,
  Icon,
  List,
  ListItem,
} from "@chakra-ui/react"

import styled from "@emotion/styled"
import Logo from "../assets/logo.svg"

import { CurrentUserDropdown } from "./CurrentUserDropdown"

export const Sidebar: React.FC = (props) => {
  // Define styles for the sidebar wrapper
  const wrapperStyles: BoxProps = {
    width: "260px",
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
    position: "fixed",
    height: "100vh",
    zIndex: 1,
  }

  return (
    <Box {...wrapperStyles}>
      <Box position="fixed" width="260px" height="100vh">
        <Flex py={6} align="center" justify="center">
          <img src={Logo} width="100" height="auto" alt="Kaspa" />
        </Flex>

        <Box paddingX={6} paddingY={2}>
          <CurrentUserDropdown />
        </Box>

        <Divider color="gray.50" my={8} />

        <Box paddingX={6}>
          <List as="nav" spacing={4}>
            <ListItem>
              <NavLink to="/">
                <NavLinkIcon as={MdDashboard} />
                Dashboard
              </NavLink>
            </ListItem>

            <ListItem>
              <NavLink to="/snapshot">
                <NavLinkIcon as={MdAccountCircle} />
                Snapshot
              </NavLink>
            </ListItem>

            <ListItem>
              <NavLink to="/matchbook">
                <NavLinkIcon as={MdGroup} />
                Matchbook
              </NavLink>
            </ListItem>

            <ListItem>
              <NavLink to="/engagement">
                <NavLinkIcon as={MdPageview} />
                Engagement
              </NavLink>
            </ListItem>

            {/* <ListItem>
              <a
                href="https://library.getkaspa.com"
                target="_blank"
                rel="noreferrer">
                <NavLinkIcon as={MdClass} />
                Library
              </a>
            </ListItem> */}
          </List>
        </Box>

        <Divider color="gray.50" my={8} />

        <Box paddingX={6}>
          <List as="nav" spacing={4}>
            <ListItem>
              <NavLink to="/logout">
                <NavLinkIcon as={MdExitToApp} />
                Logout
              </NavLink>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  )
}

export const NavLink: React.FC<any> = (props) => {
  // Default styles
  const defaultStyles = {
    fontSize: 16,
    fontWeight: 500,
  }

  // Active link styles
  const activeLinkStyles = {
    color: "#0091DF",
  }

  // Helper to determine if this link represents the current route
  const isLinkCurrent: (linkProps: LinkGetProps) => boolean = (props) => {
    return props.isCurrent || (props.isPartiallyCurrent && props.href !== "/")
  }

  // Use the `getProps` callback provided by Reach Router to apply
  // active link styling
  const handleGetProps: (linkProps: LinkGetProps) => Object = (props) => {
    return {
      style: Object.assign(
        {},
        defaultStyles,
        isLinkCurrent(props) ? activeLinkStyles : {}
      ),
    }
  }

  // Create a styled base so we can vertically align all children in the
  // link
  const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    &[aria-current="page"] {
      svg {
        color: #0091df;
      }
    }
  `

  return <StyledLink {...props} getProps={handleGetProps} />
}

export const NavLinkIcon: React.FC<{ as: any }> = (props) => {
  return (
    <Icon as={props.as} color="gray.300" width="20px" height="20px" mr={4} />
  )
}
