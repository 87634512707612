import * as React from "react"
import { Helmet } from "react-helmet"
import { RouteComponentProps, navigate } from "@reach/router"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { Hidden } from "react-grid-system"
import ReactGA from "react-ga"

import { FiLayout, FiUsers, FiPieChart, FiHeart, FiTv } from "react-icons/fi"

import { IconType } from "react-icons"

import { SiTwitter, SiFacebook, SiLinkedin } from "react-icons/si"

import {
  Flex,
  Image,
  Box,
  BoxProps,
  Heading,
  Text,
  Spinner,
  Icon,
  Spacer,
} from "@chakra-ui/react"

import { Overview, showOverviewTab } from "./public-startup-snapshot/Overview"
import { Team, showTeamTab } from "./public-startup-snapshot/Team"
import { Metrics, showMetricsTab } from "./public-startup-snapshot/Metrics"
import { Funding, showFundingTab } from "./public-startup-snapshot/Funding"
import { Media, showMediaTab } from "./public-startup-snapshot/Media"
import { Data, Variables, STARTUP } from "../../graphql/queries/startup"
import { UploadedImage } from "../ui/UploadedImage"
import { lookupCountry } from "@app/data"
import {
  Data as LogData,
  Variables as LogVariables,
  LOG_PUBLIC_STARTUP_SNAPSHOT_VIEWED,
} from "../../graphql/mutations/log-public-startup-snapshot-viewed"

export interface Props extends RouteComponentProps {
  slug?: string
  tab?: "overview" | "team" | "metrics" | "funding" | "media"
  email?: string
}

export const PublicStartupSnapshot: React.FC<Props> = (props) => {
  React.useEffect(() => {
    ReactGA.event({ category: "PublicSnapshot", action: "Viewed" })
  }, [])

  // Prepare a mutation to log the email address of the user viewing this startup
  const [mutate, mutation] = useMutation<LogData, LogVariables>(
    LOG_PUBLIC_STARTUP_SNAPSHOT_VIEWED
  )

  // Run the mutation as a side effect on mount
  React.useEffect(() => {
    mutate({
      variables: { data: { email: props.email || "", slug: props.slug || "" } },
    })
  }, [mutate, props.email, props.slug])

  // Set up and query the target Startup's snapshot
  const query = useQuery<Data, Variables>(STARTUP, {
    variables: {
      where: {
        slug: props.slug || "",
      },
    },
  })

  // Memoize the currently selected sub-page, as determined by the router
  // and defaulting to "overview"
  const selectedTab = React.useMemo(() => {
    return props.tab === undefined ? "overview" : props.tab
  }, [props.tab])

  // navigate to the applicable route based on which navbutton was clicked
  const setSelectedTab = async (tab: string) => {
    await navigate(`/startup/${props.slug}/${tab}`, {
      state: { scrollToTop: false },
    })
  }

  // Show a loading indicator if the query has not resolved yet
  if (query.loading || mutation.loading) {
    return (
      <Flex minHeight="80vh" align="center" justify="center">
        <Spinner size="lg" />
      </Flex>
    )
  }

  // Show an error message if the startup could not be retrieved
  if (
    query.data === undefined ||
    query.data.startup == null ||
    mutation.error
  ) {
    return (
      <Flex minHeight="80vh" align="center" justify="center">
        <Box backgroundColor="red.100">
          <Text color="red">
            We can't find that startup. Please verify you have a valid
            permalink.
          </Text>
        </Box>
      </Flex>
    )
  }

  // Pick off the startup for short access
  const { startup } = query.data

  // We have valid Startup data! Let's render their information
  return (
    <>
      <Helmet>
        <title>Snapshot of {startup.name}</title>
        {/* Public startup snapshot should not be indexed by google */}
        <meta name="robots" content="noindex" />
      </Helmet>
      <Box height="60px" position="relative">
        <a href="https://getkaspa.com">
          <Image
            boxSize="40px"
            src="/favicon.svg"
            position="absolute"
            top="10px"
            left="10px"
            alt="Powered by Kaspa"
          />
        </a>
        {/* TODO: Add uploaded header image display here */}
      </Box>
      <Flex
        minHeight={{ base: "0", md: "100vh" }}
        direction={{ base: "column", md: "row" }}>
        <Sidebar>
          <Flex direction="column" align={{ sm: "center", md: "start" }}>
            <Box mb="40px">
              {startup.logoImageId && (
                <Box
                  mx="auto"
                  maxW="60%"
                  minW="150px"
                  maxH="150px"
                  background="transparent">
                  <UploadedImage id={startup.logoImageId} alt={startup.name} />
                </Box>
              )}

              <Heading fontSize={32} pt={6} pb={{ sm: 0, md: 15 }}>
                {startup.name}
              </Heading>

              {startup.slogan !== null && (
                <Text fontSize={16}>{startup.slogan}</Text>
              )}
            </Box>
            {startup.locationCountry !== null && (
              <Flex w="full" mb={3}>
                Location
                <Spacer minW={1} />
                {lookupCountry(startup.locationCountry)?.label}
              </Flex>
            )}
            {startup.websiteUrl !== null && (
              <Flex w="full" mb={3}>
                Website
                <Spacer minW={1} />
                <Text as="span" textDecoration="underline">
                  <a href={startup.websiteUrl} target="_blank" rel="noreferrer">
                    {startup.websiteUrl}
                  </a>
                </Text>
              </Flex>
            )}
            {startup.industry !== null && (
              <Flex w="full" mb={3}>
                Industry
                <Spacer minW={1} />
                {startup.industry}
              </Flex>
            )}
            {startup.customerFocus !== null && (
              <Flex w="full" mb={3}>
                Customer focus
                <Spacer minW={1} />
                {startup.customerFocus}
              </Flex>
            )}
            {startup.stage !== null && (
              <Flex w="full" mb={3}>
                Stage
                <Spacer minW={1} />
                {startup.stage}
              </Flex>
            )}
            {startup.pitchDeckUrl !== null && (
              <Flex w="full" mb={3}>
                Pitch Deck
                <Spacer minW={1} />
                <Text as="span" textDecoration="underline">
                  <a
                    href={startup.pitchDeckUrl}
                    target="_blank"
                    rel="noreferrer">
                    View
                  </a>
                </Text>
              </Flex>
            )}
            {startup.verticals && startup.verticals.length >= 1 && (
              <Box w="full" mb={3}>
                Verticals
                <Box mt={2}>
                  {startup.verticals.map((vertical) => {
                    return <Tag key={vertical}>{vertical}</Tag>
                  })}
                </Box>
              </Box>
            )}

            <Flex mt="50px" w="full" justify="space-around">
              {startup.twitterUrl !== null && (
                <Box>
                  <SocialIcon
                    icon={SiTwitter}
                    hoverColor="twitter"
                    url={startup.twitterUrl}
                  />
                </Box>
              )}

              {startup.facebookUrl !== null && (
                <Box>
                  <SocialIcon
                    icon={SiFacebook}
                    hoverColor="facebook"
                    url={startup.facebookUrl}
                  />
                </Box>
              )}

              {startup.linkedinUrl !== null && (
                <Box>
                  <SocialIcon
                    icon={SiLinkedin}
                    hoverColor="linkedin"
                    url={startup.linkedinUrl}
                  />
                </Box>
              )}
            </Flex>
          </Flex>
        </Sidebar>

        <Box width="100%">
          <Box
            paddingLeft="30px"
            paddingRight="30px"
            paddingTop={{ base: 0, lg: "40px" }}>
            <Flex align="center" mx={-3} mb={5}>
              <NavButton
                leftIcon={FiLayout}
                visible={showOverviewTab(startup)}
                active={selectedTab === "overview"}
                onClick={() => setSelectedTab("overview")}
                title="Overview"
              />

              <NavButton
                leftIcon={FiUsers}
                visible={showTeamTab(startup)}
                active={selectedTab === "team"}
                onClick={() => setSelectedTab("team")}
                title="Team"
              />

              <NavButton
                leftIcon={FiPieChart}
                visible={showMetricsTab(startup)}
                active={selectedTab === "metrics"}
                onClick={() => setSelectedTab("metrics")}
                title="Metrics"
              />

              <NavButton
                leftIcon={FiHeart}
                visible={showFundingTab(startup)}
                active={selectedTab === "funding"}
                onClick={() => setSelectedTab("funding")}
                title="Funding"
              />

              <NavButton
                leftIcon={FiTv}
                visible={showMediaTab(startup)}
                active={selectedTab === "media"}
                onClick={() => setSelectedTab("media")}
                title="Media"
              />
            </Flex>

            <Box as="main" pb={5}>
              {selectedTab === "overview" && <Overview startup={startup} />}
              {selectedTab === "team" && <Team startup={startup} />}
              {selectedTab === "metrics" && <Metrics startup={startup} />}
              {selectedTab === "funding" && <Funding startup={startup} />}
              {selectedTab === "media" && <Media startup={startup} />}
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  )
}

interface NavButtonProps extends BoxProps {
  active: boolean
  leftIcon: IconType
  visible: boolean
  title: string
}

const NavButton: React.FC<NavButtonProps> = ({
  leftIcon,
  active,
  visible,
  title,
  ...props
}) => {
  const color = React.useMemo(() => {
    return active === true ? "gray.600" : "transparent"
  }, [active])

  return (
    <>
      {visible && (
        <Box
          width="100%"
          color="gray.600"
          cursor="pointer"
          m={3}
          borderColor={color}
          borderBottomWidth="1px"
          _hover={{
            borderColor: "gray.400",
          }}
          {...props}>
          <Flex align="center" pl={{ base: 0, lg: 3 }} height="45px">
            <Icon
              as={leftIcon}
              mx={{ base: "auto", lg: 2 }}
              fontSize="24px"
              title={title}
            />
            <Hidden xs sm md>
              {title}
            </Hidden>
          </Flex>
        </Box>
      )}
    </>
  )
}

const Sidebar: React.FC<BoxProps> = (props) => {
  return (
    <Box
      width={{ md: 300, lg: 420 }}
      maxWidth="100%"
      padding={6}
      bg="gray.100"
      color="gray.600"
      display={{ lg: "hidden", xl: "block" }}
      {...props}
    />
  )
}

const Tag: React.FC = (props) => {
  return (
    <Text
      fontSize={14}
      color="gray.700"
      paddingX="12px"
      minHeight="24px"
      lineHeight="23px"
      mr="4px"
      mb={1}
      backgroundColor="gray.50"
      borderColor="gray.200"
      borderWidth="1px"
      display="inline-block"
      {...props}
    />
  )
}

const SocialIcon: React.FC<{
  icon: IconType
  hoverColor: string
  url: string
}> = ({ icon, hoverColor, url }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <Icon
        as={icon}
        p={2}
        boxSize="40px"
        bg="gray.400"
        color="gray.100"
        rounded="md"
        _hover={{ backgroundColor: hoverColor }}
      />
    </a>
  )
}
