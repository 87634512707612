/**
 * Adapted from: https://andreaskeller.name/blog/react-upload-images-cloudinary
 */

import * as React from "react"
import { FieldProps } from "formik"
import { Box, Button, Text, Progress } from "@chakra-ui/react"
import { MdCloudUpload } from "react-icons/md"
import axios from "axios"

import { UploadedImage } from "../../ui/UploadedImage"
import { CloudinaryContext } from "../../../contexts/Cloudinary"

export interface Props {
  label: string
  previewWidth: string | null
}

export const FileUploadInput: React.FC<Props & FieldProps> = ({
  label,
  field,
  previewWidth,
  form,
}) => {
  const fileSelect = React.useRef<HTMLInputElement>(null)
  const [disabled, setDisabled] = React.useState<boolean>(false)
  const [progress, setProgress] = React.useState<number>(0)
  const [error, setError] = React.useState<string | null>(null)

  const { cloudName, currentEnvironment, presetName } = React.useContext(
    CloudinaryContext
  )

  function handleFiles(files: FileList | null) {
    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        uploadFile(files[i])
      }
    }
  }

  function uploadFile(file: File) {
    setDisabled(true)
    setError(null)

    const formData = new FormData()
    formData.append("upload_preset", presetName)
    formData.append("folder", `${currentEnvironment}/${field.name}`)
    formData.append("file", file)

    axios({
      method: "post",
      url: `https://api.cloudinary.com/v1_1/${cloudName}/upload`,
      data: formData,
      onUploadProgress: (e) => {
        setProgress(Math.round((e.loaded * 100.0) / e.total))
      },
    })
      .then((response) => {
        form.setFieldValue(field.name, response.data.public_id)
      })
      .catch((error) => {
        setError(error.response.data.error.message)
      })
      .then(() => {
        setDisabled(false)
        setProgress(0)
      })
  }

  return (
    <Box pb={4}>
      <Text mb={2}>{label}</Text>

      {field.value ? (
        <Box maxW={previewWidth || "100%"}>
          <UploadedImage alt={label} id={field.value} />
        </Box>
      ) : (
        <p>(none currently uploaded)</p>
      )}

      {progress > 0 && (
        <>
          <p>Uploading... ({progress}%)</p>
          <Progress size="xs" width="30%" isIndeterminate />
        </>
      )}

      {error && <Text color="red.600">Error: {error}</Text>}

      <input
        type="file"
        ref={fileSelect}
        accept="image/*"
        onChange={(e) => handleFiles(e.target.files)}
        hidden
      />
      <Button
        mt={2}
        colorScheme="blue"
        disabled={disabled}
        leftIcon={<MdCloudUpload />}
        onClick={() => fileSelect.current?.click()}>
        Upload
      </Button>
    </Box>
  )
}
