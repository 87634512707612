import * as React from "react"

import { ErrorMessage, Field, FieldProps } from "formik"
import { SingleSelectionDropdown } from "@components/ui/Dropdown"

import { FormControl, FormLabel, Flex, Icon, Text } from "@chakra-ui/react"

import { lookupCountry, COUNTRIES } from "@app/data"

export interface Props {
  isMulti: boolean
  label?: string | boolean
}

export const CountryInput: React.FC<Props & FieldProps> = props => {
  // Cache the field name
  const fieldName: string = React.useMemo(() => props.field.name, [props.field])

  // Compute if the FormControl should be displayed as invalid
  const isInvalid: boolean = React.useMemo(() => {
    return (
      props.form.errors[fieldName] !== undefined &&
      props.form.touched[fieldName] === true
    )
  }, [props.form.errors, props.form.touched, fieldName])

  // Handle when the SingleSelectionDropdown selection changes by informing
  // formik of the new value manually, given formik cannot do it automatically
  // because there's no event target
  const handleChange = (countryAlpha2: string | null) => {
    props.form.setFieldValue(props.field.name, countryAlpha2)
  }

  // We've computed all the things we need - render the SingleSelectionDropdown field
  return (
    <FormControl pb={4} width="100%" isInvalid={isInvalid}>
      {props.label !== false && (
        <FormLabel htmlFor={props.field.name}>{props.label}</FormLabel>
      )}

      <Field
        name={props.field.name}
        label={props.label}
        isMulti={props.isMulti}
        onChange={handleChange}
        defaultValue={lookupCountry(props.field.value)}
        options={COUNTRIES}
        component={SingleSelectionDropdown}
      />

      <ErrorMessage name={props.field.name}>
        {(error) => (
          <Flex color="red.500" pl={1.5} mt={2} fontSize="sm" align="center">
            <Icon name="warning" mr="0.5em" aria-hidden />
            <Text lineHeight="normal">{error}</Text>
          </Flex>
        )}
      </ErrorMessage>
    </FormControl>
  )
}
