import React from "react"

import { Helmet } from "react-helmet"

// GraphQL client
import { ApolloProvider } from "react-apollo"
import { client } from "./graphql"

// Theming and global styles
import { ChakraProvider } from "@chakra-ui/react"

// Expose the current user via an app wide context
import { Authentication } from "./contexts/Authentication"

import { CloudinaryProvider } from "./contexts/Cloudinary"

// The global router
import { Routes } from "./components/Routes"

import { customTheme } from './theme'

function App() {
  return (
    <>
      <Helmet>
        <title>Kaspa</title>
      </Helmet>
      <ChakraProvider theme={customTheme}>
        <ApolloProvider client={client}>
          <Authentication>
            <CloudinaryProvider>
              <Routes />
            </CloudinaryProvider>
          </Authentication>
        </ApolloProvider>
      </ChakraProvider>
    </>
  )
}

export default App
