import * as React from "react"
import { Box, SimpleGrid } from "@chakra-ui/react"

import { StartupUser } from "../../../graphql/types"
import { SingleSelectionDropdown, MultiSelectionDropdown } from "@components/ui/Dropdown"
import { AuthenticationContext } from "../../../contexts/Authentication"
import { COUNTRIES, CUSTOMER_FOCUSES, STAGES, VERTICALS, INDUSTRIES, lookupCountry } from "@app/data"

export type FilterKeys =
  | "customerFocuses"
  | "industries"
  | "verticals"
  | "stages"
  | "geographicPreference"

export type SelectedFilters = {
  customerFocuses: string[]
  industries: string[]
  verticals: string[]
  stages: string[]
  geographicPreference: string | null
}

export type MultiValueFilterCallback = (
  type: keyof SelectedFilters,
  values: ReadonlyArray<{ value: string }> | null
) => any

export type SingleFilterCallback = (
  type: keyof SelectedFilters,
  values: string | null
) => any

export interface Props {
  isDisabled?: boolean
  onSelectionUpdate: (filters: SelectedFilters) => any
}

export const InvestorSearchFilters: React.FC<Props> = props => {
  // Destructure props and assign default values
  const { isDisabled = false, onSelectionUpdate } = props

  const { authState } = React.useContext(AuthenticationContext)

  const currentUser = (authState as any).authedUser.user

  const defaultCountry = lookupCountry(( currentUser as StartupUser)?.startup.locationCountry)

  // Keep a piece of state which reflects filters which the user has
  // selected
  const [filters, setFilters] = React.useState<SelectedFilters>({
    customerFocuses: [],
    industries: [],
    verticals: [],
    stages: [],
    geographicPreference: null,
  })

  const handleMultiFilterSelect = React.useCallback<MultiValueFilterCallback>(
    (type, options) => {
      const value = options === null ? [] : options.map(option => option.value)
      setFilters(filters => ({ ...filters, [type]: value }))
    },
    []
  )

  // Callback which handles when user selects new filters
  const handleSingleFilterSelect = React.useCallback<SingleFilterCallback>(
    (type, value) => {
      setFilters(filters => ({ ...filters, [type]: value }))
    },
    []
  )

  // As an effect, whenever `filters` is updated we should report back
  // via the onSelectionUpdate prop
  React.useEffect(() => {
    // Create a copy of `filters`
    const updatedFilters: SelectedFilters = filters

    // Delete filters from `whereInput` that are null or empty
    Object.keys(updatedFilters).forEach(filterName => {
      if (
        !updatedFilters[filterName as FilterKeys] ||
        updatedFilters[filterName as FilterKeys]?.length === 0
      ) {
        delete updatedFilters[filterName as FilterKeys]
      }
    })

    // `updatedFilters` should now only contain arrays of strings as values, any
    // filters that the user has no defined have ben omitted
    onSelectionUpdate(updatedFilters)
  }, [filters, onSelectionUpdate])

  // Setting the default value on the select doesn't trigger the onChange event
  // so we need to trigger the same event manually
  React.useEffect(() => {
    handleSingleFilterSelect(
      "geographicPreference",
      (currentUser as StartupUser)?.startup.locationCountry
    )
  }, [currentUser, handleSingleFilterSelect])

  return (
    <SimpleGrid minChildWidth={300} spacing={4} pb={6}>
      <Box>
        <MultiSelectionDropdown
          onChange={industries => handleMultiFilterSelect("industries", industries)}
          isDisabled={isDisabled}
          placeholder="Industries"
          options={INDUSTRIES}
        />
      </Box>

      <Box>
        <MultiSelectionDropdown
          onChange={verticals => handleMultiFilterSelect("verticals", verticals)}
          isDisabled={isDisabled}
          placeholder="Verticals"
          options={VERTICALS}
        />
      </Box>

      <Box>
        <MultiSelectionDropdown
          onChange={stages => handleMultiFilterSelect("stages", stages)}
          isDisabled={isDisabled}
          placeholder="Stage"
          options={STAGES}
        />
      </Box>

      <Box>
        <MultiSelectionDropdown
          onChange={focuses =>
            handleMultiFilterSelect("customerFocuses", focuses)
          }
          isDisabled={isDisabled}
          placeholder="Customer focuses"
          options={CUSTOMER_FOCUSES}
        />
      </Box>

      <Box>
        <SingleSelectionDropdown
          onChange={countryCode =>
            handleSingleFilterSelect("geographicPreference", countryCode)
          }
          isDisabled={isDisabled}
          placeholder="Investment location"
          options={COUNTRIES}
          defaultValue={defaultCountry}
        />
      </Box>
    </SimpleGrid>
  )
}
