import * as React from "react"
import { FieldProps, ErrorMessage } from "formik"

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Radio,
  RadioGroup,
} from "@chakra-ui/react"

export interface Props {
  label?: string
}

export const BooleanInput: React.FC<Props & FieldProps> = props => {
  // Cache the field name
  const fieldName: string = React.useMemo(() => props.field.name, [props.field])

  // Compute if the FormControl should be displayed as invalid
  const isInvalid: boolean = React.useMemo(() => {
    return (
      props.form.errors[fieldName] !== undefined &&
      props.form.touched[fieldName] === true
    )
  }, [props.form.errors, props.form.touched, fieldName])

  // Memoize what the RadioGroup value should be such that we can support
  // null | "true" | "false"
  const groupValue: string = React.useMemo(() => {
    if (props.field.value === null) {
      return null
    } else {
      return props.field.value.toString()
    }
  }, [props.field.value])

  const handleYes = () => props.form.setFieldValue(props.field.name, true)
  const handleNo = () => props.form.setFieldValue(props.field.name, false)

  // We've computed all the things we need - render the TextInput field
  return (
    <FormControl marginBottom={4} isInvalid={isInvalid} width="100%">
      <FormLabel htmlFor={props.field.name}>{props.label}</FormLabel>

      <RadioGroup value={groupValue}>
        <Radio onClick={handleYes} value="true">
          Yes
        </Radio>

        <Radio onClick={handleNo} value="false">
          No
        </Radio>
      </RadioGroup>

      <ErrorMessage name={props.field.name}>
        {error => <FormErrorMessage>{error}</FormErrorMessage>}
      </ErrorMessage>
    </FormControl>
  )
}
