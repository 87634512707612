import * as React from "react"
import { FieldProps, ErrorMessage } from "formik"
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react"

import Select from "react-select"

export interface Props {
  placeholder: string
  isMulti: boolean
  options?: string[] | [string, string]
  label?: string
}

export const SelectInput: React.FC<Props & FieldProps> = props => {
  // Cache the field name
  const fieldName: string = React.useMemo(() => props.field.name, [props.field])

  // Translate props.options which is either an array of strings or an array of
  // two-element key/value arrays to a format that react-select understands..
  // that is, an array of objects
  const formattedOptions = React.useMemo(() => {
    if (!props.options) return []
    return props.options.map(value => {
      if (typeof value === "string") {
        return { label: value, value }
      } else {
        return { label: value[0], value: value[1] }
      }
    })
  }, [props.options])

  // When Formik indicates a new value is present, control and update
  // the Select's current values
  const selectedOptions = React.useMemo(() => {
    if (!props.field.value) return

    if (props.isMulti) {
      return formattedOptions.filter(
        ({ value }) => props.field.value.indexOf(value) !== -1
      )
    } else {
      return formattedOptions.find(({ value }) => value === props.field.value)
    }
  }, [props.field.value, props.isMulti, formattedOptions])

  // Handle when the Select is focussed by informing Formik that
  // it has been touched
  const onFocus = () => {
    props.form.setFieldTouched(props.field.name, true)
  }

  // Compute if the FormControl should be displayed as invalid
  const isInvalid: boolean = React.useMemo(() => {
    return (
      props.form.errors[fieldName] !== undefined &&
      props.form.touched[fieldName] === true
    )
  }, [props.form.errors, props.form.touched, fieldName])

  // Handle when the value of the Select changes by informing and translating
  // up to Formik. We need to handle cases when an option is selected, removed
  // or created. We also need to handle when the Select is cleared
  const onChange = (selected: any) => {
    if (props.isMulti) {
      const newValue =
        selected !== null ? selected.map((opt: any) => opt.value) : []
      props.form.setFieldValue(props.field.name, newValue)
    } else {
      props.form.setFieldValue(props.field.name, selected?.value)
    }
  }

  // Customise some of react-select's default styling
  const customStyles = {
    control: (provided: any, state: any) => {
      return {
        ...provided,
        minHeight: "48px",
      }
    },
  }

  return (
    <FormControl mb={4} isInvalid={isInvalid} minWidth="200px">
      <FormLabel htmlFor={props.field.name}>{props.label}</FormLabel>

      <Select
        isClearable={true}
        isSearchable={true}
        closeMenuOnSelect={!props.isMulti}
        onFocus={onFocus}
        onChange={onChange}
        options={formattedOptions}
        placeholder={props.placeholder}
        value={selectedOptions}
        isMulti={props.isMulti}
        styles={customStyles}
      />

      <ErrorMessage name={props.field.name}>
        {error => <FormErrorMessage>{error}</FormErrorMessage>}
      </ErrorMessage>
    </FormControl>
  )
}
