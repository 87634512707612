import * as React from "react"
import * as Yup from "yup"

import { Form, Formik, FormikProps, Field } from "formik"
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons"

import {
  Button,
  FormControl,
  IconButton,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react"

import { TextInput } from "./inputs/TextInput"
import { AuthenticatedStartupUser } from "../../graphql/types"
import { AuthenticationContext } from "../../contexts/Authentication"
import { TermsAndConditions } from "./TermsAndConditions"

export interface FormValues {
  email: string
  password: string
}

export interface Props {
  isDisabled?: boolean
  onLogin: (startup: AuthenticatedStartupUser) => void
  onError: () => void
}

export const validationSchema = Yup.object().shape({
  password: Yup.string().required("is required"),
  email: Yup.string().email().required("is required"),
})

export const StartupLoginForm: React.FC<Props> = (props) => {
  // Keep a piece of state allowing the user to toggle password visibility
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const { signIn } = React.useContext(AuthenticationContext)

  // Handle when the form is valid and submits
  const handleSubmit: (values: FormValues) => void = async ({
    email,
    password,
  }) => {
    try {
      const result = await signIn(email, password)

      if (result.data?.createSession) {
        props.onLogin(result.data.createSession)
      } else {
        props.onError()
      }
    } catch {
      props.onError()
    }
  }

  // Handle when the user wishes to toggle the visibility of their password
  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  // Compute the form's initial values only once
  const initialValues = React.useMemo(() => {
    return {
      email: "",
      password: "",
    }
  }, [])

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}>
      {(form: FormikProps<FormValues>) => {
        return (
          <Form>
            <Field name="email" label="Email" component={TextInput} />

            <FormControl marginBottom={4}>
              <InputGroup size="md">
                <Field
                  name="password"
                  label="Password"
                  component={TextInput}
                  secret={!showPassword}
                />

                <InputRightElement width="4.5rem">
                  <IconButton
                    aria-label={showPassword ? "Hide" : "Show"}
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    size="sm"
                    top="32px"
                    marginTop={2}
                    marginLeft={4}
                    onClick={handleTogglePassword}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Button
              fontWeight="medium"
              size="lg"
              width="100%"
              colorScheme="blue"
              type="submit"
              isLoading={form.isSubmitting || props.isDisabled}
              loadingText="Please wait">
              Login
            </Button>

            <TermsAndConditions actionText="registering your account" />
          </Form>
        )
      }}
    </Formik>
  )
}
