import { useQuery } from "@apollo/react-hooks"
import { QueryResult } from "react-apollo"
import { PublicStartupSnapshotViewingWhereInput } from "../graphql/types"

import gql from "graphql-tag"

export type PublicStartupSnapshotViewing = {
  id: string
  startup_id: string
  email: string
  inserted_at: string
}

export const PUBLIC_STARTUP_SNAPSHOT_VIEWINGS = gql`
  query publicStartupSnapshotViewings(
    $where: PublicStartupSnapshotViewingWhereInput
  ) {
    publicStartupSnapshotViewings(where: $where) {
      email
      inserted_at
    }
  }
`

export interface Data {
  publicStartupSnapshotViewings: PublicStartupSnapshotViewing[]
}

export interface Variables {
  where?: PublicStartupSnapshotViewingWhereInput
}

export type UsePublicStartupSnapshotViewingsHookResult = [
  Data["publicStartupSnapshotViewings"] | undefined | null,
  QueryResult<Data, Variables>
]

export type UsePublicStartupSnapshotViewingsHook = (
  where?: PublicStartupSnapshotViewingWhereInput
) => UsePublicStartupSnapshotViewingsHookResult

export const usePublicStartupSnapshotViewings: UsePublicStartupSnapshotViewingsHook = (
  where
) => {
  // Run the query
  const query = useQuery<Data, Variables>(PUBLIC_STARTUP_SNAPSHOT_VIEWINGS, {
    notifyOnNetworkStatusChange: true,
    variables: { where: where },
  })

  // Return the result as an 'unwrapped' tuple
  return [query.data?.publicStartupSnapshotViewings, query]
}
