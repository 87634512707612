import * as React from "react"
import { SimpleGrid } from "@chakra-ui/react"

import { TeamMember, TeamMemberProps } from "./TeamMember"

interface StartupProps {
  name: string
  teamMembers: Array<TeamMemberProps>
}
export interface Props {
  startup: StartupProps
}

/**
 * Determines whether or not the Team tab should be shown on the main
 * public Snapshot profile. If changing what fields are shown in the Team FC,
 * please update this function as well!!
 */
export const showTeamTab = (startup: StartupProps) => {
  return startup.teamMembers.length > 0
}

export const Team: React.FC<Props> = (props) => {
  // Pick off the startup prop
  const { startup } = props

  // If we've made it this far, we have some team members to render
  return (
    <SimpleGrid
      mt={{ base: "8", md: "0" }}
      columns={{ base: 1, md: 2 }}
      spacing="10">
      {startup.teamMembers.map((person, index) => {
        return (
          <TeamMember
            key={index}
            role={person.role}
            name={person.name}
            skills={person.skills}
            description={person.description}
            linkedinUrl={person.linkedinUrl}></TeamMember>
        )
      })}
    </SimpleGrid>
  )
}
