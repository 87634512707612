import * as React from "react"
import { RouteComponentProps } from "@reach/router"

// Needed to fix a Typescript issue: https://github.com/reach/router/issues/414
interface Props
  extends RouteComponentProps<{
    location: { state: { scrollToTop?: boolean } }
  }> {}

export const ScrollToTop: React.FC<Props> = (props) => {
  // Grab the current browser pathname
  const pathname = props.location && props.location.pathname
  const doScroll = props.location && props.location.state?.scrollToTop !== false

  // Scroll the viewport to 0,0 whenever the `pathname` changes
  // This can be overridden by passing `{ scrollToTop: false }` as the `state` option when calling `navigate()`
  React.useEffect(() => {
    if (doScroll) {
      window.scrollTo(0, 0)
    }
  }, [pathname, doScroll])

  // Render the remainder of the tree
  return <React.Fragment>{props.children}</React.Fragment>
}
