import gql from "graphql-tag"

export interface LogPublicStartupSnapshotViewedInput {
  email: string
  slug: string
}

export interface Data {
  logPublicStartupSnapshowViewed: boolean
}

export interface Variables {
  data: LogPublicStartupSnapshotViewedInput
}

export const LOG_PUBLIC_STARTUP_SNAPSHOT_VIEWED = gql`
  mutation logPublicStartupSnapshotViewed(
    $data: LogPublicStartupSnapshotViewedInput!
  ) {
    logPublicStartupSnapshotViewed(data: $data)
  }
`
