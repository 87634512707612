import * as React from "react"
import { FieldProps, ErrorMessage } from "formik"

import {
  Text,
  Textarea,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react"

export interface Props {
  description?: string
  label?: string
  secret?: boolean
  maxLength?: number
}

export const TextareaInput: React.FC<Props & FieldProps> = (props) => {
  // Cache the field name
  const fieldName: string = React.useMemo(() => props.field.name, [props.field])

  // Compute if the FormControl should be displayed as invalid
  const isInvalid: boolean = React.useMemo(() => {
    return (
      props.form.errors[fieldName] !== undefined &&
      props.form.touched[fieldName] === true
    )
  }, [props.form.errors, props.form.touched, fieldName])

  const maxLengthNote = React.useMemo(() => {
    if (props.maxLength) {
      const remainingChars = props.maxLength - (props.field.value || "").length
      return (
        <Text
          display="inline-block"
          float="right"
          mt={1}
          mr={-2}
          fontSize="sm"
          color={remainingChars < 0 ? "red.500" : ""}>
          max {props.maxLength} characters | {remainingChars} remaining
        </Text>
      )
    }
  }, [props.maxLength, props.field.value])

  // We've computed all the things we need - render the TextInput field
  return (
    <FormControl marginBottom={4} isInvalid={isInvalid} width="100%" {...props}>
      <FormLabel htmlFor={props.field.name}>
        {props.label} {maxLengthNote}
      </FormLabel>

      {props.description !== undefined && (
        <FormHelperText pb={2} mt={0}>
          {props.description}
        </FormHelperText>
      )}

      <Textarea
        {...props.field}
        backgroundColor="white"
        isDisabled={props.form.isSubmitting}
        id={props.field.name}
        value={props.field.value || ""}
      />

      <ErrorMessage name={props.field.name}>
        {(error) => <FormErrorMessage>{error}</FormErrorMessage>}
      </ErrorMessage>
    </FormControl>
  )
}
