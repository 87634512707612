import * as React from "react"
import * as Yup from "yup"

import { Form, Formik, FormikHelpers, FormikProps, Field } from "formik"
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons"
import { Container } from "react-grid-system"

import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  FormControl,
  IconButton,
  InputGroup,
  InputRightElement,
  CloseButton,
} from "@chakra-ui/react"

import { TextInput } from "./inputs/TextInput"
import { AuthenticatedStartupUser } from "../../graphql/types"
import { AuthenticationContext } from "../../contexts/Authentication"
import { TermsAndConditions } from "./TermsAndConditions"

export interface FormValues {
  invitationCode: string
  password: string
}

export type FormSubmissionHandler = (
  values: FormValues,
  helpers: FormikHelpers<FormValues>
) => void

export interface Props {
  invitationCode?: string
  onAccept: (user: AuthenticatedStartupUser) => void
  onError: () => void
}

export const validationSchema = Yup.object().shape({
  invitationCode: Yup.string().required("is required"),
  password: Yup.string().required("is required"),
})

export const AcceptInvitationForm: React.FC<Props> = (props) => {
  // Keep two pieces of state to determine if we need to display a general
  // form error or if the user wants to see their password in cleartext
  const [showError, setShowError] = React.useState<boolean>(false)
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const { acceptInvitation } = React.useContext(AuthenticationContext)

  // Handle when the form is valid and submits
  const handleSubmit: FormSubmissionHandler = async (
    { invitationCode, password },
    helpers
  ) => {
    return acceptInvitation(invitationCode, password)
      .then((result) => {
        if (result.data?.acceptInvitation) {
          props.onAccept(result.data.acceptInvitation)
        }
      })
      .catch((errors) => {
        // Assume first error is the validation error
        const validationError = errors["graphQLErrors"][0]

        // Grab each error from the `details` key which should already
        // match up to a form field and apply them
        Object.keys(validationError.details).forEach((fieldName) => {
          return helpers.setFieldError(
            fieldName,
            validationError.details[fieldName].join("\n")
          )
        })
      })
  }

  // Handle when the user wishes to toggle the visibility of their password
  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  // Compute the form's initial values only once
  const initialValues = React.useMemo(() => {
    return {
      invitationCode: props.invitationCode || "",
      password: "",
    }
  }, [props.invitationCode])

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}>
      {(form: FormikProps<FormValues>) => {
        return (
          <Container fluid>
            <Form>
              {showError && (
                <Alert status="error" variant="left-accent" marginBottom={4}>
                  <AlertIcon />
                  <AlertDescription mr={8}>
                    There was a problem with your invitation code. Please try
                    again
                  </AlertDescription>

                  <CloseButton
                    onClick={() => setShowError(false)}
                    position="absolute"
                    right="8px"
                    top="8px"
                  />
                </Alert>
              )}

              <Field
                name="invitationCode"
                label="Invitation code"
                component={TextInput}
              />

              <FormControl marginBottom={4}>
                <InputGroup size="md">
                  <Field
                    name="password"
                    label="Desired password"
                    component={TextInput}
                    secret={!showPassword}
                  />

                  <InputRightElement width="4.5rem">
                    <IconButton
                      aria-label={showPassword ? "Hide" : "Show"}
                      icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                      size="sm"
                      top="28px"
                      marginTop={2}
                      marginLeft={4}
                      onClick={handleTogglePassword}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button
                fontWeight="medium"
                size="lg"
                width="100%"
                colorScheme="blue"
                type="submit"
                isLoading={form.isSubmitting}
                loadingText="Please wait">
                Accept Invitation
              </Button>

              <TermsAndConditions actionText="registering your account" />
            </Form>
          </Container>
        )
      }}
    </Formik>
  )
}
