import * as React from "react"
import { RouteComponentProps, navigate } from "@reach/router"
import { Box, BoxProps, Button, SimpleGrid, Text } from "@chakra-ui/react"

import { AuthenticationContext } from "../../contexts/Authentication"
import { PageHeader } from "../PageHeader"
import { StartupUser } from "../../graphql/types"

const GridBox: React.FC<BoxProps> = ({ children, ...boxProps }) => {
  return (
    <Box padding={8} background="white" rounded="md" {...boxProps}>
      {children}
    </Box>
  )
}

export const Dashboard: React.FC<RouteComponentProps> = (props) => {
  // Bring in the current startup so we can render their name
  const { authState } = React.useContext(AuthenticationContext)

  switch (authState.name) {
    case "AUTHENTICATED": {
      const headingText =
        authState.userKind === "startup_user"
          ? `Heya ${
              (authState.authedUser.user as StartupUser).startup.name
            } 👋 what do you want to do today?`
          : "Dashboard"
      return (
        <>
          <PageHeader headingText={headingText} />
          <SimpleGrid
            columns={{ lg: 2, xl: 3 }}
            spacing={{ sm: "10px", xl: "20px" }}>
            <GridBox>
              <Text fontSize={22} pb={4} fontWeight="bold" color="gray.700">
                Learn how to use Kaspa
              </Text>

              <Text fontSize={14}>
                Check out our guides and tutorials on how to make the most of
                Kaspa’s tools.
              </Text>

              <Button
                isDisabled
                mt={4}
                onClick={() =>
                  (window.location.href =
                    "https://library.diligr.io/category/making-the-most-of-diligr/")
                }>
                Show me how
              </Button>
            </GridBox>

            <GridBox>
              <Text fontSize={22} pb={4} fontWeight="bold" color="gray.700">
                Complete my Snapshot
              </Text>

              <Text fontSize={14}>
                Your centralised source of truth for your pitch.
              </Text>

              <Button mt={4} onClick={() => navigate("/snapshot")}>
                Complete Now
              </Button>
            </GridBox>

            <GridBox>
              <Text fontSize={22} pb={4} fontWeight="bold" color="gray.700">
                Search for Investors
              </Text>

              <Text fontSize={14}>
                Filter through our investor database to find the best investors
                suited to you.
              </Text>

              <Button mt={4} onClick={() => navigate("/matchbook")}>
                Search Now
              </Button>
            </GridBox>

            <GridBox>
              <Text fontSize={22} pb={4} fontWeight="bold" color="gray.700">
                Get Investment-Ready in our Library
              </Text>

              <Text fontSize={14}>
                Not sure where to start? Check out the Library for everything
                you need to know about capital raising.
              </Text>

              <Button
                mt={4}
                onClick={() =>
                  (window.location.href = "https://library.diligr.io")
                }>
                Go to Library
              </Button>
            </GridBox>

            <GridBox>
              <Text fontSize={22} pb={4} fontWeight="bold" color="gray.700">
                Check out my investor engagement
              </Text>

              <Text fontSize={14}>Track who is viewing your Snapshot.</Text>

              <Button mt={4} onClick={() => navigate("/engagement")}>
                Check Engagement
              </Button>
            </GridBox>

            <GridBox>
              <Text fontSize={22} pb={4} fontWeight="bold" color="gray.700">
                Invite a friend
              </Text>

              <Text fontSize={14}>
                Invite your fellow founders and bump them up the waitlist.
              </Text>

              <Button
                mt={4}
                onClick={() =>
                  (window.location.href =
                    "https://diligrco.typeform.com/to/ubLSbIWu")
                }>
                Invite Now
              </Button>
            </GridBox>
          </SimpleGrid>

          <GridBox mt={{ sm: "10px", xl: "20px" }}>
            <Text fontSize={22} pb={4} fontWeight="bold" color="gray.700">
              Give us feedback
            </Text>

            <Text fontSize={14}>
              We want your feedback and are more than happy to listen.
            </Text>

            <Button
              mt={4}
              onClick={() =>
                (window.location.href =
                  "https://diligrco.typeform.com/to/m2W7a0Ww#email=xxxxx&name=xxxxx")
              }>
              Feedback
            </Button>
          </GridBox>
        </>
      )
    }
    default:
      return null
  }
}
