import gql from "graphql-tag"
import { AuthenticatedStartupUser } from "../types"

export interface SessionCreateInput {
  email: string
  password: string
}

export interface Data {
  createSession: AuthenticatedStartupUser
}

export interface Variables {
  data: SessionCreateInput
}

export const CREATE_STARTUP_SESSION = gql`
  mutation createSession($data: SessionCreateInput!) {
    createSession(data: $data) {
      user {
        id
        givenName
        familyName
        email
        insertedAt
        startup {
          id
          name
          locationCountry
        }
      }
      session {
        token
      }
    }
  }
`
