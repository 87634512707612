import * as React from "react"
import { SnapshotField } from "../../ui/PublicSnapshot/SnapshotField"

interface StartupProps {
  recurringRevenueValue: number | null
  recurringRevenueTimeframe: string | null
  growthRateValue: number | null
  growthRateTimeframe: string | null
  churnRateValue: number | null
  churnRateTimeframe: string | null
  userCount: number | null
  ltvCacRatio: string | null
  burnRate: number | null
  dailyActiveUsers: number | null
  revenueGrowthRate: number | null
  revenueGrowthRateTimeframe: string | null
  ltv: number | null
  cac: number | null
  customerCount: number | null
  annualSignedContractValue: number | null
  highlightedMetricValue: number | null
  highlightedMetricUnitLabel: string | null
}
export interface Props {
  startup: StartupProps
}

/**
 * Determines whether or not the Metrics tab should be shown on the main
 * public Snapshot profile. If changing what fields are shown in the Metrics FC,
 * please update this function as well!!
 */
export const showMetricsTab = (startup: StartupProps) => {
  return (
    startup.dailyActiveUsers !== null ||
    startup.customerCount !== null ||
    startup.recurringRevenueValue !== null ||
    startup.burnRate !== null ||
    startup.growthRateValue !== null ||
    startup.revenueGrowthRate !== null ||
    startup.churnRateValue !== null ||
    startup.annualSignedContractValue !== null ||
    startup.userCount !== null ||
    startup.ltv !== null ||
    startup.cac !== null ||
    startup.ltvCacRatio !== null ||
    startup.highlightedMetricValue !== null ||
    startup.highlightedMetricUnitLabel !== null
  )
}

export const Metrics: React.FC<Props> = (props) => {
  // Pick off the startup from props
  const { startup } = props

  return (
    <React.Fragment>
      <SnapshotField
        title="Our Highlighted Metric"
        value={startup.highlightedMetricValue}
      >
        {startup.highlightedMetricValue} {startup.highlightedMetricUnitLabel}
      </SnapshotField>

      <SnapshotField
        title="Daily Active Users"
        value={startup.dailyActiveUsers}
      />

      <SnapshotField
        title="Paying Customer Count"
        value={startup.customerCount}
      />

      <SnapshotField
        title="Recurring Revenue"
        value={startup.recurringRevenueValue}>
        ${startup.recurringRevenueValue?.toLocaleString()} per{" "}
        {startup.recurringRevenueTimeframe}
      </SnapshotField>

      <SnapshotField title="Burn Rate" value={startup.burnRate}>
        {startup.burnRate?.toLocaleString()}%
      </SnapshotField>

      <SnapshotField title="Growth Rate" value={startup.growthRateValue}>
        {startup.growthRateValue?.toLocaleString()}% per{" "}
        {startup.growthRateTimeframe}
      </SnapshotField>

      <SnapshotField
        title="Revenue Growth Rate"
        value={startup.revenueGrowthRate}>
        {startup.revenueGrowthRate?.toLocaleString()} per{" "}
        {startup.revenueGrowthRateTimeframe}
      </SnapshotField>

      <SnapshotField title="Churn Rate" value={startup.churnRateValue}>
        {startup.churnRateValue?.toLocaleString()}% per{" "}
        {startup.churnRateTimeframe}
      </SnapshotField>

      <SnapshotField
        title="Annual Signed Contract Value"
        value={startup.annualSignedContractValue}
      />

      <SnapshotField title="User Count" value={startup.userCount}>
        {startup.userCount?.toLocaleString()}
      </SnapshotField>

      <SnapshotField title="LTV" value={startup.ltv} />

      <SnapshotField title="CAC" value={startup.cac} />

      <SnapshotField title="LTV:CAC Ratio" value={startup.ltvCacRatio} />
    </React.Fragment>
  )
}
