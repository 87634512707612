import * as React from "react"
import { Box, BoxProps, Flex, IconButton, Text } from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import { motion } from "framer-motion"

export interface Props extends BoxProps {
  primaryText: string
  subText: string
  isActive: boolean
  icon: string
  onClick: () => void
  onContinueClick: () => void
  showContinueButton?: boolean
  isDisabled?: boolean
}

export const LargeSelectionTile: React.FC<Props> = props => {
  // Pick off props that are not BoxProps
  const {
    primaryText,
    subText,
    showContinueButton,
    onContinueClick,
    onClick,
    isActive,
    isDisabled,
    ...rest
  } = props

  // Handle when the tile is clicked
  const handleTileClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    onClick()
  }

  // Handle when the continue button is clicked
  const handleContinueClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onContinueClick()
  }

  // Define verbose styles for the outer wrapper here so the component
  // doesn't wrap :)
  const wrapperProps: BoxProps = {
    border: "solid 2px",
    borderRadius: "4px",
    borderColor: isActive ? "blue.500" : "transparent",
    boxShadow: isActive ? "none" : "0 5px 12px rgba(194,204,212,0.5)",
    cursor: isDisabled ? "not-allowed" : "pointer",
    onClick: isDisabled ? () => {} : handleTileClick,
  }

  return (
    <Box {...wrapperProps} {...rest}>
      <Flex padding={8} minHeight="124px" align="center">
        <Flex
          align="center"
          justify="center"
          width={16}
          height={16}
          mr={8}
          border="solid 1px"
          borderColor="blue.500"
          borderRadius={4}>
          <img src={props.icon} alt="" />
        </Flex>

        <Box>
          <Text fontSize={20} color="gray.800">
            {props.primaryText}
          </Text>

          <Text fontSize={16} color="gray.400">
            {props.subText}
          </Text>
        </Box>

        {isActive && (
          <Box marginLeft="auto">
            <motion.div initial={{ x: 16 }} animate={{ x: 0 }}>
              <IconButton
                onClick={handleContinueClick}
                icon={<ArrowForwardIcon />}
                aria-label="Continue"
                colorScheme="blue"
              />
            </motion.div>
          </Box>
        )}
      </Flex>
    </Box>
  )
}
