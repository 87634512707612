import { useLazyQuery } from "@apollo/react-hooks"
import { QueryResult } from "react-apollo"

import { Data, Variables, STARTUP } from "../graphql/queries/startup"

export type StartupSnapshotHookResult = [
  Data["startup"] | undefined,
  QueryResult<Data, Variables>
]

export type StartupWhereUniqueKey = "id" | "slug"

export type StartupSnapshotHook = (
  key: StartupWhereUniqueKey,
  value: string
) => StartupSnapshotHookResult

export const useStartupSnapshot: StartupSnapshotHook = (key, value) => {
  // We want to lazily call the query after we check that the current user
  // is a StartupUser. We must set this up now because hooks cannot be called
  // conditionally
  const [runQuery, query] = useLazyQuery<Data, Variables>(STARTUP, {
    variables: {
      where: {
        [key]: value,
      },
    },
  })

  // We're past the point of conditional early return so we should trigger
  // the query if we haven't already
  if (!query.called) {
    runQuery()
  }

  // Return a tuple containing the unwrapped query data and the whole query
  // object
  return [query.data && query.data.startup, query]
}
