import * as React from "react"
import { DeleteIcon, SmallAddIcon } from "@chakra-ui/icons"

import {
  Divider,
  Flex,
  IconButton,
  Button,
  ButtonGroup,
  Box,
  Text,
} from "@chakra-ui/react"

import {
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  Field,
  FieldArray,
} from "formik"

import { TextInput } from "../inputs/TextInput"
import { useUpdateStartup } from "../../../hooks/useUpdateStartup"
import { applyAbsintheErrors } from "../../../utilities/forms"

export interface FormValues {
  twitterUrl: string | null
  facebookUrl: string | null
  linkedinUrl: string | null
  pressAndAwardsUrls: string[] | null
}

export interface Props {
  startup: FormValues
  onCommit: () => void
  onError: () => void
}

export type FormUtils = FormikHelpers<FormValues>

export const Media: React.FC<Props> = (props) => {
  // Prepare mutation for updating over the API
  const [updateStartup] = useUpdateStartup()

  // Set up the initial values for the form
  const initialValues: FormValues = React.useMemo(() => {
    return {
      twitterUrl: props.startup.twitterUrl,
      facebookUrl: props.startup.facebookUrl,
      linkedinUrl: props.startup.linkedinUrl,
      pressAndAwardsUrls: props.startup.pressAndAwardsUrls,
    }
  }, [props.startup])

  // Set up the submission handler
  const onSubmit = async (values: FormValues, form: FormUtils) => {
    // Build the mutation's parameteres
    const params = {
      variables: {
        data: values,
      },
    }

    // Fire off the mutation and take action accordingly based on the result
    return updateStartup(params)
      .then((result) => {
        if (result.data?.updateStartup) {
          props.onCommit()
        }
      })
      .catch((errorResponse) => {
        props.onError()
        applyAbsintheErrors(errorResponse, form)
      })
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(form: FormikProps<FormValues>) => {
        return (
          <Form>
            <Text pb={4}>
              Showcase your digital marketing presence, media, and public
              achievements.
            </Text>

            <Field
              name="twitterUrl"
              label="Company Twitter URL"
              placeholder="https://twitter.com/your-username"
              component={TextInput}
            />
            <Field
              name="facebookUrl"
              label="Company Facebook URL"
              placeholder="https://facebook.com/your-username"
              component={TextInput}
            />
            <Field
              name="linkedinUrl"
              label="Company LinkedIn URL"
              placeholder="https://linkedin.com/your-username"
              component={TextInput}
            />

            <Box pb={2}>
              <label htmlFor="pressAndAwardsUrls">Press &amp; Awards</label>
            </Box>

            <FieldArray
              name="pressAndAwardsUrls"
              render={(arrayHelpers) => {
                return (
                  <div>
                    {form.values.pressAndAwardsUrls &&
                      form.values.pressAndAwardsUrls.map(
                        (link: string, index: number) => {
                          return (
                            <Flex key={index} align="flex-start">
                              <Field
                                name={`pressAndAwardsUrls.${index}`}
                                label={false}
                                component={TextInput}
                                placeholder="https://some-link.com/your-awesome-startup"
                                paddingRight={8}
                              />

                              <IconButton
                                aria-label="Remove Link"
                                icon={<DeleteIcon />}
                                colorScheme="red"
                                size="lg"
                                marginLeft={2}
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </Flex>
                          )
                        }
                      )}

                    <Button
                      leftIcon={<SmallAddIcon />}
                      onClick={() => arrayHelpers.push("")}>
                      Add New Link
                    </Button>
                  </div>
                )
              }}
            />

            <Divider my={8} borderColor="gray.300" />

            <ButtonGroup spacing={4}>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={form.isSubmitting}>
                Update Snapshot
              </Button>

              <Button
                type="reset"
                variant="ghost"
                colorScheme="red"
                isDisabled={form.isSubmitting}>
                Reset
              </Button>
            </ButtonGroup>
          </Form>
        )
      }}
    </Formik>
  )
}
