import * as React from "react"
import {
  Divider,
  Button,
  ButtonGroup,
  Box,
  Text,
  Stack,
} from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons"

import {
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  Field,
  FieldArray,
} from "formik"

import { TextInput } from "../inputs/TextInput"
import { useUpdateStartup } from "../../../hooks/useUpdateStartup"
import { applyAbsintheErrors } from "../../../utilities/forms"
import { StartupTeamMember } from "../../../graphql/types"

export interface FormValues {
  teamMembers: StartupTeamMember[]
}

export interface Props {
  startup: FormValues
  onCommit: () => void
  onError: () => void
}

export type FormUtils = FormikHelpers<FormValues>

export const TeamMembers: React.FC<Props> = (props) => {
  // Prepare mutation for updating over the API
  const [updateStartup] = useUpdateStartup()

  // Set up the initial values for the form
  const initialValues: FormValues = React.useMemo(() => {
    return {
      teamMembers: props.startup.teamMembers.map((member) => ({
        name: member.name,
        role: member.role,
        skills: member.skills,
        description: member.description,
        linkedinUrl: member.linkedinUrl,
      })),
    }
  }, [props.startup])

  // Create a blank team member that we can push on to the stack
  const newTeamMember = React.useMemo<StartupTeamMember>(() => {
    return {
      name: "",
      role: "",
      skills: [],
      description: "",
      linkedinUrl: "",
    }
  }, [])

  // Set up the submission handler
  const onSubmit = async (values: FormValues, form: FormUtils) => {
    // Build the mutation's parameteres

    const params = {
      variables: {
        data: values,
      },
    }

    // Fire off the mutation and take action accordingly based on the result
    return updateStartup(params)
      .then((result) => {
        if (result.data?.updateStartup) {
          props.onCommit()
        }
      })
      .catch((errorResponse) => {
        props.onError()
        applyAbsintheErrors(errorResponse, form)
      })
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(form: FormikProps<FormValues>) => {
        return (
          <Form>
            <Box mb={6}>
              <Text pb={4}>
                Tell us about the brains behind the operations and highlight
                your core strengths.
              </Text>

              <FieldArray
                name="teamMembers"
                render={(arrayHelpers) => {
                  return (
                    <div>
                      {form.values.teamMembers &&
                        form.values.teamMembers.map(
                          (member: StartupTeamMember, index: number) => {
                            return (
                              <div key={index}>
                                <Stack key={index} isInline>
                                  <Box pr={1}>
                                    <Field
                                      name={`teamMembers.${index}.name`}
                                      label="Name"
                                      component={TextInput}
                                    />
                                  </Box>

                                  <Box pr={1}>
                                    <Field
                                      name={`teamMembers.${index}.role`}
                                      label="Role"
                                      component={TextInput}
                                    />
                                  </Box>

                                  <Field
                                    name={`teamMembers.${index}.linkedinUrl`}
                                    label="LinkedIn Profile URL"
                                    component={TextInput}
                                  />
                                </Stack>

                                <Box>
                                  <Field
                                    name={`teamMembers.${index}.description`}
                                    label="Brief Bio"
                                    component={TextInput}
                                  />
                                </Box>

                                <Divider mb={6} />
                              </div>
                            )
                          }
                        )}

                      <Button
                        leftIcon={<AddIcon />}
                        onClick={() => arrayHelpers.push(newTeamMember)}>
                        Add New Team Member
                      </Button>
                    </div>
                  )
                }}
              />

              <Divider my={8} borderColor="gray.300" />

              <ButtonGroup spacing={4}>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={form.isSubmitting}>
                  Update Snapshot
                </Button>

                <Button
                  type="reset"
                  variant="ghost"
                  colorScheme="red"
                  isDisabled={form.isSubmitting}>
                  Reset
                </Button>
              </ButtonGroup>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}
