import * as React from "react"
import { SnapshotField } from "../../ui/PublicSnapshot/SnapshotField"

interface StartupProps {
  previousFundingRounds: string | null
  currentFundingRound: string | null
  fundingUsesAndRunway: string | null
  investmentAsk: number | null
  investorPreferences: string[]
  dealType: string | null
  valuation: number | null
}
export interface Props {
  startup: StartupProps
}

/**
 * Determines whether or not the Funding tab should be shown on the main
 * public Snapshot profile. If changing what fields are shown in the Funding FC,
 * please update this function as well!!
 */
export const showFundingTab = (startup: StartupProps) => {
  return (
    startup.previousFundingRounds !== null ||
    startup.currentFundingRound !== null ||
    startup.fundingUsesAndRunway !== null ||
    startup.investmentAsk !== null ||
    startup.investorPreferences.length > 0 ||
    startup.dealType !== null ||
    startup.valuation !== null
  )
}

export const Funding: React.FC<Props> = (props) => {
  // Pick off the startup from props
  const { startup } = props

  const formatAsCurrency = (value: number | null) => {
    if (value !== null) {
      // When we support multiple currencies, this can be tweaked to use currency formatting
      return `$${new Intl.NumberFormat().format(value)}`
    }
  }

  return (
    <React.Fragment>
      <SnapshotField
        title="Investor Preferences"
        value={startup.investorPreferences[0]}>
        {startup.investorPreferences.join(", ")}
      </SnapshotField>

      <SnapshotField title="Security" value={startup.dealType} />

      <SnapshotField
        title="Previous Funding Rounds"
        value={startup.previousFundingRounds}
      />

      <SnapshotField
        title="Current Funding Round"
        value={startup.currentFundingRound}
      />

      <SnapshotField
        title="Funding Uses and Runway"
        value={startup.fundingUsesAndRunway}
      />

      <SnapshotField title="Investment Ask" value={startup.investmentAsk}>
        {formatAsCurrency(startup.investmentAsk)}
      </SnapshotField>

      <SnapshotField title="Valuation" value={startup.valuation}>
        {formatAsCurrency(startup.valuation)}
      </SnapshotField>
    </React.Fragment>
  )
}
