import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box, BoxProps, SimpleGrid } from "@chakra-ui/react"

import { AuthenticationContext } from "../../contexts/Authentication"
import { PageHeader } from "../PageHeader"
import { StartupUser } from "../../graphql/types"

import { PublicStartupSnapshotViewings } from "@app/components/startups/PublicStartupSnapshotViewings"

const GridBox: React.FC<BoxProps> = ({ children, ...boxProps }) => {
  return (
    <Box padding={8} background="white" rounded="md" {...boxProps}>
      {children}
    </Box>
  )
}

export const Engagement: React.FC<RouteComponentProps> = (props) => {
  // Bring in the current startup so we can render their name
  const { authState } = React.useContext(AuthenticationContext)

  switch (authState.name) {
    case "AUTHENTICATED": {
      const headingText =
        authState.userKind === "startup_user"
          ? `See who's interacting with you 🔍`
          : "Engagement"
      const startup_id = (authState.authedUser.user as StartupUser).startup.id
      return (
        <>
          <PageHeader headingText={headingText} />
          <SimpleGrid columns={1} spacing={{ sm: "10px", xl: "20px" }}>
            <GridBox mb={{ sm: "10px", xl: "20px" }}>
              <PublicStartupSnapshotViewings startup_id={startup_id} />
            </GridBox>
          </SimpleGrid>
        </>
      )
    }
    default:
      return null
  }
}
