import gql from "graphql-tag"
import { useMutation, MutationTuple } from "@apollo/react-hooks"
import { Startup, StartupSnapshotFields } from "../graphql/types"

export const UPDATE_STARTUP = gql`
  mutation updateStartup($data: UpdateStartupInput!) {
    updateStartup(data: $data) {
      id
      name
      slug
      logoImageId
      slogan
      websiteUrl
      locationCountry
      industry
      verticals
      customerFocus
      stage
      seekingFunding
      problemToSolve
      problemSolution
      marketDescription
      valueProposition
      businessModel
      competitionDescription
      pressAndAwardsUrls
      twitterUrl
      facebookUrl
      linkedinUrl
      previousFundingRounds
      currentFundingRound
      fundingUsesAndRunway
      recurringRevenueValue
      recurringRevenueTimeframe
      userCount
      growthRateValue
      growthRateTimeframe
      churnRateValue
      churnRateTimeframe
      ltvCacRatio
      contractsInPipelineCount
      burnRate
      burnRateTimeframe
      revenueGrowthRate
      revenueGrowthRateTimeframe
      ltv
      cac
      dailyActiveUsers
      customerCount
      annualSignedContractValue
      profileCompletenessPercentage
      highlightedMetricValue
      highlightedMetricUnitLabel
      teamMembers {
        name
        role
        skills
        description
        linkedinUrl
      }
    }
  }
`

export type UpdateStartupInput = Partial<StartupSnapshotFields>
export type QueriedStartup = Pick<Startup, "id"> & StartupSnapshotFields

export interface Data {
  updateStartup: QueriedStartup
}

export interface Variables {
  data: UpdateStartupInput
}

export const useUpdateStartup: () => MutationTuple<Data, Variables> = () => {
  // This hook is a thin wrapper around useMutation, simply call useMutation and
  // return the result
  return useMutation<Data, Variables>(UPDATE_STARTUP)
}
