import * as React from "react"
import { RouteComponentProps, navigate } from "@reach/router"

import { AuthenticatedStartupUser } from "../../../graphql/types"

import { AcceptInvitationBasic } from "./AcceptInvitationBasic"

export interface Props extends RouteComponentProps {
  invitationCode?: string
}

export const AcceptInvitation: React.FC<Props> = (props) => {
  // Handle when a new Startup is successfully registered
  const handleAcceptInvitation = (startup: AuthenticatedStartupUser) =>
    navigate("/")

  // Handle when an error occured during Startup creation
  const handleStartupError = () => Promise.resolve(void 0)

  return (
    <AcceptInvitationBasic
      handleAcceptInvitation={handleAcceptInvitation}
      handleStartupError={handleStartupError}
      invitationCode={props.invitationCode}
    />
  )
}
