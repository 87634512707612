import * as React from "react"
import { Form, Formik, FormikHelpers, FormikProps, Field } from "formik"
import { InfoIcon } from "@chakra-ui/icons"

import { TextInput } from "../inputs/TextInput"
import { SelectInput } from "../inputs/SelectInput"

import { useUpdateStartup } from "../../../hooks/useUpdateStartup"
import {
  applyAbsintheErrors,
  nullifyBlankStringFields,
} from "../../../utilities/forms"

import {
  Divider,
  Button,
  ButtonGroup,
  Flex,
  Box,
  Stack,
  Text,
} from "@chakra-ui/react"

export interface FormValues {
  burnRate: number | null
  burnRateTimeframe: string | null
  growthRateValue: number | null
  growthRateTimeframe: string | null
  churnRateValue: number | null
  churnRateTimeframe: string | null
  userCount: number | null
  recurringRevenueValue: number | null
  recurringRevenueTimeframe: string | null
  revenueGrowthRate: number | null
  revenueGrowthRateTimeframe: string | null
  ltv: number | null
  cac: number | null
  ltvCacRatio: string | null
  dailyActiveUsers: number | null
  customerCount: number | null
  annualSignedContractValue: number | null
  highlightedMetricValue: number | null
  highlightedMetricUnitLabel: string | null
}

export interface Props {
  startup: FormValues
  onCommit: () => void
  onError: () => void
}

export type FormUtils = FormikHelpers<FormValues>

export const Metrics: React.FC<Props> = (props) => {
  // Prepare mutation for updating over the API
  const [updateStartup] = useUpdateStartup()

  // Set up the initial values for the form
  const initialValues: FormValues = React.useMemo(() => {
    return {
      burnRate: props.startup.burnRate,
      burnRateTimeframe: props.startup.burnRateTimeframe,
      growthRateValue: props.startup.growthRateValue,
      growthRateTimeframe: props.startup.growthRateTimeframe,
      churnRateValue: props.startup.churnRateValue,
      churnRateTimeframe: props.startup.churnRateTimeframe,
      userCount: props.startup.userCount,
      recurringRevenueValue: props.startup.recurringRevenueValue,
      recurringRevenueTimeframe: props.startup.recurringRevenueTimeframe,
      revenueGrowthRate: props.startup.revenueGrowthRate,
      revenueGrowthRateTimeframe: props.startup.revenueGrowthRateTimeframe,
      ltv: props.startup.ltv,
      cac: props.startup.cac,
      ltvCacRatio: props.startup.ltvCacRatio,
      dailyActiveUsers: props.startup.dailyActiveUsers,
      customerCount: props.startup.customerCount,
      annualSignedContractValue: props.startup.annualSignedContractValue,
      highlightedMetricValue: props.startup.highlightedMetricValue,
      highlightedMetricUnitLabel: props.startup.highlightedMetricUnitLabel,
    }
  }, [props.startup])

  // Set up the submission handler
  const onSubmit = async (values: FormValues, form: FormUtils) => {
    // Build the mutation's parameteres
    const params = {
      variables: {
        data: nullifyBlankStringFields(values),
      },
    }

    // Fire off the mutation and take action accordingly based on the result
    return updateStartup(params)
      .then((result) => {
        if (result.data?.updateStartup) {
          props.onCommit()
        }
      })
      .catch((errorResponse) => {
        props.onError()
        applyAbsintheErrors(errorResponse, form)
      })
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(form: FormikProps<FormValues>) => {
        return (
          <Form>
            <Text fontSize={18} pb={4}>
              Now let’s back up your pitch with some numbers. Add in the
              relevant evidence of your traction below, keeping in mind that not
              all of the metrics will apply to your business.
            </Text>

            <Text mb={4} color="gray.500">
              <InfoIcon mr="6px" position="relative" top="-1px" />
              Make sure you have the data and calculations to back up any metric
              you add. Investors will ask for it!
            </Text>

            <Flex>
              <Box pr={2}>
                <Field
                  name="burnRate"
                  label="Burn Rate"
                  component={TextInput}
                  isNumber
                />
              </Box>

              <Field
                name="burnRateTimeframe"
                label="Per"
                component={SelectInput}
                options={["Month", "Year"]}
              />
            </Flex>

            <Flex>
              <Box pr={2}>
                <Field
                  name="growthRateValue"
                  label="Growth Rate %"
                  component={TextInput}
                  isNumber
                />
              </Box>

              <Field
                name="growthRateTimeframe"
                label="Per"
                component={SelectInput}
                options={["Month", "Year"]}
              />
            </Flex>

            <Flex>
              <Box pr={2}>
                <Field
                  name="churnRateValue"
                  label="Churn Rate %"
                  type="number"
                  component={TextInput}
                  isNumber
                />
              </Box>

              <Field
                name="churnRateTimeframe"
                label="Per"
                component={SelectInput}
                options={["Month", "Year"]}
              />
            </Flex>

            <Stack isInline={true}>
              <Box>
                <Field
                  name="userCount"
                  label="Total User Count"
                  component={TextInput}
                  isNumber
                />
              </Box>

              <Box>
                <Field
                  name="dailyActiveUsers"
                  label="Daily Active Users"
                  component={TextInput}
                  isNumber
                />
              </Box>
            </Stack>

            <Text fontSize={18} mt={4}>
              Your Highlighted Metric
            </Text>

            <Text mb={4}>
              What's the most important number you want investors to know about?
            </Text>

            <Flex>
              <Box pr={2}>
                <Field
                  name="highlightedMetricValue"
                  label="Value"
                  component={TextInput}
                  placeholder="eg. 200"
                  isNumber
                />
              </Box>

              <Field
                name="highlightedMetricUnitLabel"
                label="Unit"
                component={TextInput}
                placeholder="eg. widgets per month"
              />
            </Flex>

            <Text fontSize={18} mt={4}>
              Post-revenue Metrics
            </Text>

            <Text mb={4}>
              Complete this section if your company is generating consistent
              revenue
            </Text>

            <Flex>
              <Box pr={2}>
                <Field
                  name="recurringRevenueValue"
                  label="Recurring Revenue"
                  component={TextInput}
                  isNumber
                />
              </Box>

              <Field
                name="recurringRevenueTimeframe"
                label="Per"
                component={SelectInput}
                options={["Month", "Year"]}
              />
            </Flex>

            <Flex>
              <Box pr={2}>
                <Field
                  name="revenueGrowthRate"
                  label="Revenue Growth Rate"
                  component={TextInput}
                  isNumber
                />
              </Box>

              <Field
                name="revenueGrowthRateTimeframe"
                label="Per"
                component={SelectInput}
                options={["Month", "Year"]}
              />
            </Flex>

            <Flex>
              <Box pr={1}>
                <Field name="ltv" label="LTV" component={TextInput} isNumber />
              </Box>

              <Box pl={1} pr={1}>
                <Field name="cac" label="CAC" component={TextInput} isNumber />
              </Box>

              <Box pl={1}>
                <Field
                  name="ltvCacRatio"
                  label="LTV:CAC Ratio"
                  component={TextInput}
                />
              </Box>
            </Flex>

            <Flex>
              <Box pr={1}>
                <Field
                  name="customerCount"
                  label="Number of Customers"
                  component={TextInput}
                  isNumber
                />
              </Box>

              <Box pl={1}>
                <Field
                  name="annualSignedContractValue"
                  label="Annual $ of signed contracts"
                  component={TextInput}
                  isNumber
                />
              </Box>
            </Flex>

            <Divider my={8} borderColor="gray.300" />

            <ButtonGroup spacing={4}>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={form.isSubmitting}>
                Update Snapshot
              </Button>

              <Button
                type="reset"
                variant="ghost"
                colorScheme="red"
                isDisabled={form.isSubmitting}>
                Reset
              </Button>
            </ButtonGroup>
          </Form>
        )
      }}
    </Formik>
  )
}
