import * as React from "react"
import { RouteComponentProps, Redirect, useLocation } from "@reach/router"
import { Flex, Spinner } from "@chakra-ui/react"

import { AuthenticationContext } from "../contexts/Authentication"

export const Authenticated: React.FC<RouteComponentProps> = (props) => {
  const { authState } = React.useContext(AuthenticationContext)
  const location = useLocation()

  switch (authState.name) {
    case "UNAUTHENTICATED": {
      if (location.pathname !== '/login') {
        return <Redirect to='/login' noThrow/>
      } else {
        return <React.Fragment>{props.children}</React.Fragment>
      }
    }
    case "AUTHENTICATION_IN_PROGRESS": {
      return (
        <Flex direction="column" align="center" justify="center" height="70vh">
          <Spinner size="xl" color="blue.500" />
        </Flex>
      )
    }
    case "AUTHENTICATED": {
      return <React.Fragment>{props.children}</React.Fragment>
    }
  }
}
