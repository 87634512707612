import * as React from "react"
import * as Yup from "yup"

import { Form, Formik, FormikProps, Field } from "formik"

import { Button } from "@chakra-ui/react"

import { TextInput } from "./inputs/TextInput"
import { TermsAndConditions } from "./TermsAndConditions"

export interface FormValues {
  email: string
}

export interface Props {
  isDisabled?: boolean
  onLogin: () => void
  onError: () => void
}

export const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("is required"),
})

export const EmailWallForm: React.FC<Props> = (props) => {
  // Handle when the form is valid and submits
  const handleSubmit: (values: FormValues) => void = async ({ email }) => {
    // update local storage
    try {
      localStorage.setItem("currentEmail", email)
      props.onLogin()
    } catch {
      props.onError()
    }
  }

  // Compute the form's initial values only once
  const initialValues = React.useMemo(() => {
    return {
      email: "",
    }
  }, [])

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}>
      {(form: FormikProps<FormValues>) => {
        return (
          <Form>
            <Field name="email" label="Email" component={TextInput} />

            <Button
              fontWeight="medium"
              size="lg"
              width="100%"
              colorScheme="blue"
              type="submit"
              isLoading={form.isSubmitting || props.isDisabled}
              loadingText="Please wait">
              Access
            </Button>

            <TermsAndConditions actionText="accessing this Snapshot" />
          </Form>
        )
      }}
    </Formik>
  )
}
