import * as React from "react"
import { SnapshotField } from "../../ui/PublicSnapshot/SnapshotField"

interface StartupProps {
  problemToSolve: string | null
  problemSolution: string | null
  marketDescription: string | null
  valueProposition: string | null
  businessModel: string | null
  competitionDescription: string | null
}
export interface Props {
  startup: StartupProps
}

/**
 * Determines whether or not the Overview tab should be shown on the main
 * public Snapshot profile. If changing what fields are shown in the Overview FC,
 * please update this function as well!!
 */
export const showOverviewTab = (startup: StartupProps) => {
  return (
    startup.problemToSolve !== null ||
    startup.problemSolution !== null ||
    startup.marketDescription !== null ||
    startup.valueProposition !== null ||
    startup.businessModel !== null ||
    startup.competitionDescription !== null
  )
}

export const Overview: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <SnapshotField title="The Problem" value={props.startup.problemToSolve} />

      <SnapshotField
        title="The Solution"
        value={props.startup.problemSolution}
      />

      <SnapshotField
        title="The Market"
        value={props.startup.marketDescription}
      />

      <SnapshotField
        title="Unique Value Proposition"
        value={props.startup.valueProposition}
      />

      <SnapshotField
        title="Business Model"
        value={props.startup.businessModel}
      />

      <SnapshotField
        title="Competition"
        value={props.startup.competitionDescription}
      />
    </React.Fragment>
  )
}
