import * as React from "react"
import { RouteComponentProps } from "@reach/router"

import { StartupSnapshot } from "./StartupSnapshot"
import { InvestorProfile } from "./InvestorProfile"
import { AuthenticationContext, UserKind } from "../../contexts/Authentication"

export const Snapshot: React.FC<RouteComponentProps> = (props) => {
  const { authState } = React.useContext(AuthenticationContext)
  const userKind: UserKind = (authState as any).userKind

  switch (userKind) {
    case "startup_user":
      // @ts-ignore
      const id = authState.authedUser.user.startup.id
      return <StartupSnapshot id={id} {...props} />
    case "investor_user":
      return <InvestorProfile {...props} />
  }
}
